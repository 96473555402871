import polyglotI18nProvider from 'ra-i18n-polyglot';
// @ts-ignore
import ru from 'ra-language-russian';

const translations = { ru };

export const I18nProvider = polyglotI18nProvider(
  // @ts-ignore
  (locale) => translations[locale],
  'ru',
  [{ locale: 'ru', name: 'Russian' }],
);
