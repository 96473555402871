import { LocalStorageKey } from './keys';

type LocalStorageObjectType<T> = {
  value: T;
};

export const LocalStorageService = {
  set<T>(key: LocalStorageKey, newValue: T) {
    localStorage.setItem(key, JSON.stringify({ value: newValue }));
  },

  get<T>(key: LocalStorageKey) {
    const persistedString = localStorage.getItem(key);

    if (persistedString) {
      return (JSON.parse(persistedString) as LocalStorageObjectType<T>).value;
    }

    return null;
  },

  remove(key: LocalStorageKey) {
    localStorage.removeItem(key);
  },
};
