import { useEffect, useState } from 'react';

import { api } from '@shared/api';

export const usePaymentsSettings = () => {
  const [isPaymentsEnabled, setIsPaymentsEnabled] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.settings.adminSystemSettingsControllerGetSystemSetting({
        kind: 'payments_enabled',
      });

      setIsPaymentsEnabled(response.data.data.enabled);
    };

    fetchData();
  }, []);

  const togglePaymentsSettings = async () => {
    if (isPaymentsEnabled) {
      await api.settings.adminSystemSettingsControllerSetPaymentsEnabled({
        adminSetPaymentsEnabledSystemSettingDto: { enabled: false },
      });

      setIsPaymentsEnabled(false);
    } else {
      await api.settings.adminSystemSettingsControllerSetPaymentsEnabled({
        adminSetPaymentsEnabledSystemSettingDto: { enabled: true },
      });

      setIsPaymentsEnabled(true);
    }
  };

  return { togglePaymentsSettings, isPaymentsEnabled };
};
