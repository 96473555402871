import { AuthProvider } from 'react-admin';

import { SignInAdminDto, api } from '@shared/api';
import { LocalStorageKey, LocalStorageService } from '@shared/services';

export const authProvider: AuthProvider = {
  login: async (signInAdminDto: SignInAdminDto) => {
    const { data } = await api.auth.adminsControllerSignInAdmin({ signInAdminDto });

    LocalStorageService.set<string>(LocalStorageKey.accessToken, data.accessToken);
  },

  logout: async () => {
    LocalStorageService.remove(LocalStorageKey.accessToken);
  },

  checkAuth: () => {
    const token = LocalStorageService.get<string>(LocalStorageKey.accessToken);

    return token ? Promise.resolve() : Promise.reject();
  },

  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      LocalStorageService.remove(LocalStorageKey.accessToken);

      return Promise.reject();
    }

    return Promise.resolve();
  },

  getIdentity: async () => {
    return { id: 1, fullName: 'Администратор' };
  },

  getPermissions: async () => {
    return 'admin';
  },
};
