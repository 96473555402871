import { FC } from 'react';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  ImageField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
} from 'react-admin';

import { Feedback } from '@features';

export const ShowFeedback: FC = () => {
  const columnStyles = { '& .RaDatagrid-rowCell': { width: '33%' } };

  return (
    <Show title="Обратная связь" sx={{ maxWidth: '100%' }}>
      <SimpleShowLayout>
        <TextField label="Имя пользователя" source="user.nickname" />
        <TextField label="Имя пользователя указанное в обратной связи" source="name" />

        <TextField label="id" source="id" />
        <TextField label="user Id" source="userId" />
        <EmailField label="Почта" source="user.email" />
        <DateField label="Дата создания" source="createdAt" locales="ru" />
        <TextField label="Страна" source="user.country" />
        <TextField label="Язык" source="user.language" />
        <TextField label="Пол" source="user.sex" />
        <BooleanField label="Стилист" source="user.isStylist" />
        <BooleanField label="Обработанно" source="isChecked" />

        <ArrayField label="Изображения" source="feedbackImages" emptyText="Изображения отсутствуют">
          <Datagrid bulkActionButtons={false} sx={columnStyles} style={{ maxWidth: '100%' }}>
            <UrlField
              label="Ссылка"
              source="imageUrl"
              style={{ maxWidth: '500px', display: 'block', overflow: 'scroll' }}
              emptyText="ссылки на изображения отсутствуют"
            />
            <ImageField source="imageUrl" label="Изображение" emptyText="Изображения отсутствуют" />
          </Datagrid>
        </ArrayField>

        <TextField label="Сообщение" source="message" emptyText="Сообщение не указано" />

        <Feedback.ToggleChecked />
      </SimpleShowLayout>
    </Show>
  );
};
