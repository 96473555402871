/* tslint:disable */
/* eslint-disable */
/**
 * MODUSE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminPaginatedUserFeedbacksResponseDto } from '../models';
// @ts-ignore
import { AdminUpdateUserFeedbackDto } from '../models';
// @ts-ignore
import { AdminUserFeedbackResponseDto } from '../models';
/**
 * V1AdminUserFeedbacksApi - axios parameter creator
 * @export
 */
export const V1AdminUserFeedbacksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get paginated user feedbacks
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {Array<string>} [userIds] 
         * @param {boolean} [isChecked] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserFeedbacksControllerGetPaginatedUserFeedbacks: async (limit?: number, page?: number, userIds?: Array<string>, isChecked?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/user-feedbacks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = Array.from(userIds);
            }

            if (isChecked !== undefined) {
                localVarQueryParameter['isChecked'] = isChecked;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get single user feedback by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserFeedbacksControllerGetUserFeedback: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUserFeedbacksControllerGetUserFeedback', 'id', id)
            const localVarPath = `/v1/admin/user-feedbacks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user feedback
         * @param {string} id 
         * @param {AdminUpdateUserFeedbackDto} adminUpdateUserFeedbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserFeedbacksControllerUpdateUserFeedback: async (id: string, adminUpdateUserFeedbackDto: AdminUpdateUserFeedbackDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUserFeedbacksControllerUpdateUserFeedback', 'id', id)
            // verify required parameter 'adminUpdateUserFeedbackDto' is not null or undefined
            assertParamExists('adminUserFeedbacksControllerUpdateUserFeedback', 'adminUpdateUserFeedbackDto', adminUpdateUserFeedbackDto)
            const localVarPath = `/v1/admin/user-feedbacks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateUserFeedbackDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminUserFeedbacksApi - functional programming interface
 * @export
 */
export const V1AdminUserFeedbacksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminUserFeedbacksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get paginated user feedbacks
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {Array<string>} [userIds] 
         * @param {boolean} [isChecked] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserFeedbacksControllerGetPaginatedUserFeedbacks(limit?: number, page?: number, userIds?: Array<string>, isChecked?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedUserFeedbacksResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserFeedbacksControllerGetPaginatedUserFeedbacks(limit, page, userIds, isChecked, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get single user feedback by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserFeedbacksControllerGetUserFeedback(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserFeedbackResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserFeedbacksControllerGetUserFeedback(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user feedback
         * @param {string} id 
         * @param {AdminUpdateUserFeedbackDto} adminUpdateUserFeedbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserFeedbacksControllerUpdateUserFeedback(id: string, adminUpdateUserFeedbackDto: AdminUpdateUserFeedbackDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserFeedbackResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserFeedbacksControllerUpdateUserFeedback(id, adminUpdateUserFeedbackDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminUserFeedbacksApi - factory interface
 * @export
 */
export const V1AdminUserFeedbacksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminUserFeedbacksApiFp(configuration)
    return {
        /**
         * 
         * @summary Get paginated user feedbacks
         * @param {V1AdminUserFeedbacksApiAdminUserFeedbacksControllerGetPaginatedUserFeedbacksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserFeedbacksControllerGetPaginatedUserFeedbacks(requestParameters: V1AdminUserFeedbacksApiAdminUserFeedbacksControllerGetPaginatedUserFeedbacksRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminPaginatedUserFeedbacksResponseDto> {
            return localVarFp.adminUserFeedbacksControllerGetPaginatedUserFeedbacks(requestParameters.limit, requestParameters.page, requestParameters.userIds, requestParameters.isChecked, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get single user feedback by ID
         * @param {V1AdminUserFeedbacksApiAdminUserFeedbacksControllerGetUserFeedbackRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserFeedbacksControllerGetUserFeedback(requestParameters: V1AdminUserFeedbacksApiAdminUserFeedbacksControllerGetUserFeedbackRequest, options?: AxiosRequestConfig): AxiosPromise<AdminUserFeedbackResponseDto> {
            return localVarFp.adminUserFeedbacksControllerGetUserFeedback(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user feedback
         * @param {V1AdminUserFeedbacksApiAdminUserFeedbacksControllerUpdateUserFeedbackRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserFeedbacksControllerUpdateUserFeedback(requestParameters: V1AdminUserFeedbacksApiAdminUserFeedbacksControllerUpdateUserFeedbackRequest, options?: AxiosRequestConfig): AxiosPromise<AdminUserFeedbackResponseDto> {
            return localVarFp.adminUserFeedbacksControllerUpdateUserFeedback(requestParameters.id, requestParameters.adminUpdateUserFeedbackDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminUserFeedbacksControllerGetPaginatedUserFeedbacks operation in V1AdminUserFeedbacksApi.
 * @export
 * @interface V1AdminUserFeedbacksApiAdminUserFeedbacksControllerGetPaginatedUserFeedbacksRequest
 */
export interface V1AdminUserFeedbacksApiAdminUserFeedbacksControllerGetPaginatedUserFeedbacksRequest {
    /**
     * 
     * @type {number}
     * @memberof V1AdminUserFeedbacksApiAdminUserFeedbacksControllerGetPaginatedUserFeedbacks
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof V1AdminUserFeedbacksApiAdminUserFeedbacksControllerGetPaginatedUserFeedbacks
     */
    readonly page?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof V1AdminUserFeedbacksApiAdminUserFeedbacksControllerGetPaginatedUserFeedbacks
     */
    readonly userIds?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof V1AdminUserFeedbacksApiAdminUserFeedbacksControllerGetPaginatedUserFeedbacks
     */
    readonly isChecked?: boolean
}

/**
 * Request parameters for adminUserFeedbacksControllerGetUserFeedback operation in V1AdminUserFeedbacksApi.
 * @export
 * @interface V1AdminUserFeedbacksApiAdminUserFeedbacksControllerGetUserFeedbackRequest
 */
export interface V1AdminUserFeedbacksApiAdminUserFeedbacksControllerGetUserFeedbackRequest {
    /**
     * 
     * @type {string}
     * @memberof V1AdminUserFeedbacksApiAdminUserFeedbacksControllerGetUserFeedback
     */
    readonly id: string
}

/**
 * Request parameters for adminUserFeedbacksControllerUpdateUserFeedback operation in V1AdminUserFeedbacksApi.
 * @export
 * @interface V1AdminUserFeedbacksApiAdminUserFeedbacksControllerUpdateUserFeedbackRequest
 */
export interface V1AdminUserFeedbacksApiAdminUserFeedbacksControllerUpdateUserFeedbackRequest {
    /**
     * 
     * @type {string}
     * @memberof V1AdminUserFeedbacksApiAdminUserFeedbacksControllerUpdateUserFeedback
     */
    readonly id: string

    /**
     * 
     * @type {AdminUpdateUserFeedbackDto}
     * @memberof V1AdminUserFeedbacksApiAdminUserFeedbacksControllerUpdateUserFeedback
     */
    readonly adminUpdateUserFeedbackDto: AdminUpdateUserFeedbackDto
}

/**
 * V1AdminUserFeedbacksApi - object-oriented interface
 * @export
 * @class V1AdminUserFeedbacksApi
 * @extends {BaseAPI}
 */
export class V1AdminUserFeedbacksApi extends BaseAPI {
    /**
     * 
     * @summary Get paginated user feedbacks
     * @param {V1AdminUserFeedbacksApiAdminUserFeedbacksControllerGetPaginatedUserFeedbacksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUserFeedbacksApi
     */
    public adminUserFeedbacksControllerGetPaginatedUserFeedbacks(requestParameters: V1AdminUserFeedbacksApiAdminUserFeedbacksControllerGetPaginatedUserFeedbacksRequest = {}, options?: AxiosRequestConfig) {
        return V1AdminUserFeedbacksApiFp(this.configuration).adminUserFeedbacksControllerGetPaginatedUserFeedbacks(requestParameters.limit, requestParameters.page, requestParameters.userIds, requestParameters.isChecked, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get single user feedback by ID
     * @param {V1AdminUserFeedbacksApiAdminUserFeedbacksControllerGetUserFeedbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUserFeedbacksApi
     */
    public adminUserFeedbacksControllerGetUserFeedback(requestParameters: V1AdminUserFeedbacksApiAdminUserFeedbacksControllerGetUserFeedbackRequest, options?: AxiosRequestConfig) {
        return V1AdminUserFeedbacksApiFp(this.configuration).adminUserFeedbacksControllerGetUserFeedback(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user feedback
     * @param {V1AdminUserFeedbacksApiAdminUserFeedbacksControllerUpdateUserFeedbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUserFeedbacksApi
     */
    public adminUserFeedbacksControllerUpdateUserFeedback(requestParameters: V1AdminUserFeedbacksApiAdminUserFeedbacksControllerUpdateUserFeedbackRequest, options?: AxiosRequestConfig) {
        return V1AdminUserFeedbacksApiFp(this.configuration).adminUserFeedbacksControllerUpdateUserFeedback(requestParameters.id, requestParameters.adminUpdateUserFeedbackDto, options).then((request) => request(this.axios, this.basePath));
    }
}
