/* tslint:disable */
/* eslint-disable */
/**
 * MODUSE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminPaginatedTagsResponseDto } from '../models';
// @ts-ignore
import { AdminTagResponseDto } from '../models';
// @ts-ignore
import { AdminUpdateTagDto } from '../models';
/**
 * V1AdminTagsApi - axios parameter creator
 * @export
 */
export const V1AdminTagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get paginated tags list
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTagsControllerGetPaginatedTagsList: async (limit?: number, page?: number, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tag by ID
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTagsControllerGetTagById: async (tagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('adminTagsControllerGetTagById', 'tagId', tagId)
            const localVarPath = `/v1/admin/tags/{tagId}`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update existing tag\'s localizations and machine names
         * @param {string} tagId 
         * @param {AdminUpdateTagDto} adminUpdateTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTagsControllerUpdateTag: async (tagId: string, adminUpdateTagDto: AdminUpdateTagDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('adminTagsControllerUpdateTag', 'tagId', tagId)
            // verify required parameter 'adminUpdateTagDto' is not null or undefined
            assertParamExists('adminTagsControllerUpdateTag', 'adminUpdateTagDto', adminUpdateTagDto)
            const localVarPath = `/v1/admin/tags/{tagId}`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateTagDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminTagsApi - functional programming interface
 * @export
 */
export const V1AdminTagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminTagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get paginated tags list
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTagsControllerGetPaginatedTagsList(limit?: number, page?: number, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedTagsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTagsControllerGetPaginatedTagsList(limit, page, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tag by ID
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTagsControllerGetTagById(tagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTagResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTagsControllerGetTagById(tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update existing tag\'s localizations and machine names
         * @param {string} tagId 
         * @param {AdminUpdateTagDto} adminUpdateTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTagsControllerUpdateTag(tagId: string, adminUpdateTagDto: AdminUpdateTagDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTagResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTagsControllerUpdateTag(tagId, adminUpdateTagDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminTagsApi - factory interface
 * @export
 */
export const V1AdminTagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminTagsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get paginated tags list
         * @param {V1AdminTagsApiAdminTagsControllerGetPaginatedTagsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTagsControllerGetPaginatedTagsList(requestParameters: V1AdminTagsApiAdminTagsControllerGetPaginatedTagsListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminPaginatedTagsResponseDto> {
            return localVarFp.adminTagsControllerGetPaginatedTagsList(requestParameters.limit, requestParameters.page, requestParameters.filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tag by ID
         * @param {V1AdminTagsApiAdminTagsControllerGetTagByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTagsControllerGetTagById(requestParameters: V1AdminTagsApiAdminTagsControllerGetTagByIdRequest, options?: AxiosRequestConfig): AxiosPromise<AdminTagResponseDto> {
            return localVarFp.adminTagsControllerGetTagById(requestParameters.tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update existing tag\'s localizations and machine names
         * @param {V1AdminTagsApiAdminTagsControllerUpdateTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTagsControllerUpdateTag(requestParameters: V1AdminTagsApiAdminTagsControllerUpdateTagRequest, options?: AxiosRequestConfig): AxiosPromise<AdminTagResponseDto> {
            return localVarFp.adminTagsControllerUpdateTag(requestParameters.tagId, requestParameters.adminUpdateTagDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminTagsControllerGetPaginatedTagsList operation in V1AdminTagsApi.
 * @export
 * @interface V1AdminTagsApiAdminTagsControllerGetPaginatedTagsListRequest
 */
export interface V1AdminTagsApiAdminTagsControllerGetPaginatedTagsListRequest {
    /**
     * 
     * @type {number}
     * @memberof V1AdminTagsApiAdminTagsControllerGetPaginatedTagsList
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof V1AdminTagsApiAdminTagsControllerGetPaginatedTagsList
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof V1AdminTagsApiAdminTagsControllerGetPaginatedTagsList
     */
    readonly filter?: string
}

/**
 * Request parameters for adminTagsControllerGetTagById operation in V1AdminTagsApi.
 * @export
 * @interface V1AdminTagsApiAdminTagsControllerGetTagByIdRequest
 */
export interface V1AdminTagsApiAdminTagsControllerGetTagByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof V1AdminTagsApiAdminTagsControllerGetTagById
     */
    readonly tagId: string
}

/**
 * Request parameters for adminTagsControllerUpdateTag operation in V1AdminTagsApi.
 * @export
 * @interface V1AdminTagsApiAdminTagsControllerUpdateTagRequest
 */
export interface V1AdminTagsApiAdminTagsControllerUpdateTagRequest {
    /**
     * 
     * @type {string}
     * @memberof V1AdminTagsApiAdminTagsControllerUpdateTag
     */
    readonly tagId: string

    /**
     * 
     * @type {AdminUpdateTagDto}
     * @memberof V1AdminTagsApiAdminTagsControllerUpdateTag
     */
    readonly adminUpdateTagDto: AdminUpdateTagDto
}

/**
 * V1AdminTagsApi - object-oriented interface
 * @export
 * @class V1AdminTagsApi
 * @extends {BaseAPI}
 */
export class V1AdminTagsApi extends BaseAPI {
    /**
     * 
     * @summary Get paginated tags list
     * @param {V1AdminTagsApiAdminTagsControllerGetPaginatedTagsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminTagsApi
     */
    public adminTagsControllerGetPaginatedTagsList(requestParameters: V1AdminTagsApiAdminTagsControllerGetPaginatedTagsListRequest = {}, options?: AxiosRequestConfig) {
        return V1AdminTagsApiFp(this.configuration).adminTagsControllerGetPaginatedTagsList(requestParameters.limit, requestParameters.page, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tag by ID
     * @param {V1AdminTagsApiAdminTagsControllerGetTagByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminTagsApi
     */
    public adminTagsControllerGetTagById(requestParameters: V1AdminTagsApiAdminTagsControllerGetTagByIdRequest, options?: AxiosRequestConfig) {
        return V1AdminTagsApiFp(this.configuration).adminTagsControllerGetTagById(requestParameters.tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update existing tag\'s localizations and machine names
     * @param {V1AdminTagsApiAdminTagsControllerUpdateTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminTagsApi
     */
    public adminTagsControllerUpdateTag(requestParameters: V1AdminTagsApiAdminTagsControllerUpdateTagRequest, options?: AxiosRequestConfig) {
        return V1AdminTagsApiFp(this.configuration).adminTagsControllerUpdateTag(requestParameters.tagId, requestParameters.adminUpdateTagDto, options).then((request) => request(this.axios, this.basePath));
    }
}
