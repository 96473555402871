import { FC } from 'react';
import { FunctionField } from 'react-admin';

type LanguageFieldProps = {
  label: string;
  source: string;
};

const languageNames = new Intl.DisplayNames(['ru'], {
  type: 'language',
});

export const LanguageField: FC<LanguageFieldProps> = ({ label, source }) => {
  return (
    <FunctionField
      label={label}
      source={source}
      render={(record: { language: string }) => languageNames.of(record.language)}
    />
  );
};
