import { FC } from 'react';
import { TextField, useRecordContext } from 'react-admin';

import { AdminItemResponseDto } from '@shared/api';

type TypeFieldProps = {
  label: string;
};

export const TypeField: FC<TypeFieldProps> = () => {
  const record = useRecordContext<AdminItemResponseDto>();

  const ruTypeLocalization = record.itemType.localizations.find(
    (localization) => localization.language === 'ru',
  );

  return (
    <TextField
      sortable={false}
      source="typeName"
      record={{
        typeName: ruTypeLocalization?.localization || record.itemType.localizations[0]?.localization,
      }}
    />
  );
};
