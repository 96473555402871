import { InternalAxiosRequestConfig } from 'axios';

import { httpClient } from '@shared/api';
import { LocalStorageKey, LocalStorageService } from '@shared/services';

export const axiosInterceptors = {
  setup: () => {
    httpClient.interceptors.request.use(
      async (config) => {
        const token = LocalStorageService.get<string>(LocalStorageKey.accessToken);

        if (!token) {
          return config;
        }

        const headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };

        return { ...config, headers } as InternalAxiosRequestConfig<any>;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    httpClient.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            LocalStorageService.remove(LocalStorageKey.accessToken);
          }
          throw error.response.data;
        }

        if (error.data) {
          throw error.data;
        }

        throw error;
      },
    );
  },
};
