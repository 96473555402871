import React, { FC } from 'react';
import { ArrayInput, AutocompleteInput, BooleanInput, SimpleFormIterator, TextInput } from 'react-admin';

import { langs } from '@shared/constants';

type LocalizationInputProps = {
  label: string;
  name: string;
  source: string;
};

export const LocalizationInput: FC<LocalizationInputProps> = ({ label, source, name }) => {
  return (
    <ArrayInput source={source} name={name} label={label}>
      <SimpleFormIterator inline={true} disableReordering={true} disableClear={true} fullWidth={true}>
        <AutocompleteInput source="language" choices={langs} label="Язык" sx={{ width: '34%' }} />
        <TextInput source="localization" label="Перевод" sx={{ width: '35%' }} />
        <BooleanInput source="isDefault" label="Основное" defaultValue={false} sx={{ width: '23%' }} />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
