import { DataProvider, GetListParams } from 'react-admin';

import { api } from '@shared/api';
import { ResourceName, SortOrderType } from '@shared/types';

export const dataProvider: DataProvider<ResourceName> = {
  getList: async (resource, params: GetListParams) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const apiClient = getListApiMapping[resource];

    const query: Record<string, unknown> = {};
    if (params.filter) {
      if (resource === ResourceName.Item) {
        query['filter'] = params.filter.filter;
        query['itemTypeId'] = params.filter.typeFilter;
        query['tagIds'] = params.filter.tagFilter;
      } else if (resource === ResourceName.Outfit) {
        query['filter'] = params.filter.filter;
        query['tagIds'] = params.filter.tagFilter;
      } else if (resource === ResourceName.User) {
        query['filter'] = params.filter.filter;
      } else if (resource === ResourceName.ItemBrand) {
        query['filter'] = params.filter.filter;
      } else if (resource === ResourceName.ItemType) {
        query['filter'] = params.filter.filter;
      } else if (resource === ResourceName.Tag) {
        query['filter'] = params.filter.filter;
      } else if (resource === ResourceName.Feedback) {
        query['filter'] = params.filter.filter;
      } else if (resource === ResourceName.UserAssessments) {
        query['filter'] = params.filter.filter;
      } else if (resource === ResourceName.UserReports) {
        query['filter'] = params.filter.filter;
      }
    }

    const { data } = await apiClient({
      // @ts-ignore
      sortField: field,
      // @ts-ignore
      sortOrder: order.toLocaleLowerCase() as SortOrderType,
      filter: params.filter,
      limit: perPage,
      page,
      itemTypeId: params.meta?.itemTypeId,
      tagIds: params.meta?.tagIds,
      ...query,
    });

    return {
      data: data.data as any,
      total: data.meta.total,
      pageInfo: {
        hasNextPage: data.meta.page < data.meta.pageCount,
        hasPreviousPage: data.meta.page !== 1,
      },
    };
  },

  getOne: async (resource, { id }) => {
    const apiClient = getOneApiMapping[resource];

    const response = await apiClient?.({
      itemId: id,
      userId: id,
      id,
      lookId: id,
      itemTypeId: id,
      reportId: id,
    });

    return { data: response?.data as any };
  },

  create: async (resource, { data }) => {
    const apiClient = createApiMapping[resource];

    const response = await apiClient?.({ adminCreateItemDto: { ...data } });

    return { data: response?.data as any };
  },

  update: async (resource, { id, data }) => {
    const apiClient = updateApiMapping[resource];

    const response = await apiClient?.({
      itemId: id,
      adminUpdateItemDto: { ...data },
    });

    return { data: response?.data as any };
  },

  getMany: async () => {
    //TODO доделать если будет нужно

    return { data: [{} as any] };
  },

  getManyReference: async () => {
    //TODO доделать если будет нужно

    return { data: [{} as any], total: 0 };
  },

  updateMany: async () => {
    //TODO доделать если будет нужно

    return { data: [{} as any] };
  },

  delete: async () => {
    //TODO доделать если будет нужно

    return { data: {} as any };
  },

  deleteMany: async () => {
    //TODO доделать если будет нужно

    return { data: [{} as any] };
  },
};

const getListApiMapping = {
  [ResourceName.User]: api.users.adminUsersControllerGetUsersList.bind(api.users),
  [ResourceName.ItemBrand]: api.items.adminItemsControllerGetItemBrandsList.bind(api.items),
  [ResourceName.ItemType]: api.itemTypes.adminItemTypesControllerGetItemTypesList.bind(api.itemTypes),
  [ResourceName.Item]: api.items.adminItemsControllerGetItemsList.bind(api.items),
  [ResourceName.Outfit]: api.outfits.adminLooksControllerGetLooksList.bind(api.outfits),
  [ResourceName.Feedback]: api.feedback.adminUserFeedbacksControllerGetPaginatedUserFeedbacks.bind(
    api.feedback,
  ),
  [ResourceName.Tag]: api.tags.adminTagsControllerGetPaginatedTagsList.bind(api.tags),
  [ResourceName.UserAssessments]:
    api.userAssessments.adminUserAssessmentsControllerGetPaginatedUserAssessments.bind(api.userAssessments),
  [ResourceName.UserReports]: api.userReports.adminUserReportsControllerGetUserReportsList.bind(
    api.userReports,
  ),
};

const getOneApiMapping = {
  [ResourceName.User]: api.users.adminUsersControllerGetUserById.bind(api.users),
  [ResourceName.Item]: api.items.adminItemsControllerGetItemById.bind(api.items),
  [ResourceName.Outfit]: api.outfits.adminLooksControllerGetLookById.bind(api.outfits),
  [ResourceName.Feedback]: api.feedback.adminUserFeedbacksControllerGetUserFeedback.bind(api.feedback),
  [ResourceName.ItemBrand]: undefined,
  [ResourceName.ItemType]: api.itemTypes.adminItemTypesControllerGetItemTypeById.bind(api.itemTypes),
  [ResourceName.UserAssessments]: api.userAssessments.adminUserAssessmentsControllerGetUserAssessment.bind(
    api.userAssessments,
  ),
  [ResourceName.Tag]: undefined,
  [ResourceName.UserReports]: api.userReports.adminUserReportsControllerGetUserReportById.bind(
    api.userReports,
  ),
};

const createApiMapping = {
  [ResourceName.Item]: api.items.adminItemsControllerCreateItem.bind(api.items),
  [ResourceName.ItemBrand]: undefined,
  [ResourceName.Outfit]: undefined,
  [ResourceName.Feedback]: undefined,
  [ResourceName.ItemType]: undefined,
  [ResourceName.Tag]: undefined,
  [ResourceName.User]: undefined,
  [ResourceName.UserAssessments]: undefined,
  [ResourceName.UserReports]: undefined,
};

const updateApiMapping = {
  [ResourceName.Item]: api.items.adminItemsControllerUpdateItem.bind(api.items),
  [ResourceName.ItemBrand]: undefined,
  [ResourceName.Outfit]: undefined,
  [ResourceName.ItemType]: undefined,
  [ResourceName.Feedback]: undefined,
  [ResourceName.Tag]: undefined,
  [ResourceName.User]: undefined,
  [ResourceName.UserAssessments]: undefined,
  [ResourceName.UserReports]: undefined,
};
