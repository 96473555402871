import { FC } from 'react';
import { AutocompleteInput, useGetList } from 'react-admin';

import { AdminItemTypeResponseDto } from '@shared/api';
import { ResourceName } from '@shared/types';

type TypeInputProps = {
  label: string;
  name: string;
  source: string;
};

export const TypeInput: FC<TypeInputProps> = ({ label, source, name, ...props }) => {
  // TODO доделать пагинацию

  const { data, isLoading } = useGetList<AdminItemTypeResponseDto>(ResourceName.ItemType, {
    pagination: { page: 1, perPage: 1000 },
  });

  const choices = data?.map((itemType) => {
    const ruLocalization = itemType.localizations.find((localization) => localization.language === 'ru');

    return {
      id: itemType.id,
      name: ruLocalization?.localization || itemType.localizations[0]?.localization,
    };
  });

  return (
    <AutocompleteInput
      label={label}
      source={source}
      name={name}
      isLoading={isLoading}
      choices={choices || []}
      fullWidth={true}
      {...props}
    />
  );
};
