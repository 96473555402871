import { FC } from 'react';
import {
  BooleanField,
  DateField,
  ImageField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
} from 'react-admin';

export const ShowUser: FC = () => {
  return (
    <Show title="Пользователь" sx={{ maxWidth: '100%' }}>
      <SimpleShowLayout>
        <ImageField label="Фотография пользователя" source="avatarUrl" emptyText="Фото не загружено" />

        <UrlField
          label="Ссылка"
          source="avatarUrl"
          style={{ maxWidth: '500px', display: 'block', overflow: 'scroll' }}
          emptyText="Фото не загружено"
        />

        <TextField label="id" source="id" />
        <TextField label="Имя пользователя" source="nickname" />
        <BooleanField label="Стилист" source="isStylist" />
        <DateField label="Дата создания" source="createdAt" locales="ru" />
        <BooleanField label="Подписка" source="hasSubscription" sortable={false} />
        <TextField label="Язык" source="language" />
        <TextField label="Страна" source="country" />
        <TextField label="Почта" source="email" />
        <TextField label="Пол" source="sex" />
      </SimpleShowLayout>
    </Show>
  );
};
