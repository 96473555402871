import { FC } from 'react';
import { Datagrid, DateField, EmailField, List, TextField } from 'react-admin';

export const UserAssessmentsList: FC = () => {
  return (
    <List title="Пользовательские оценки" sort={{ field: 'rate', order: 'asc' }} perPage={20}>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField label="Имя пользователя" source="user.nickname" sortable={false} />
        <EmailField label="Почта" source="user.email" sortable={false} />
        <DateField source="createdAt" label="Дата создания" sortable={true} locales="ru" />
        <TextField label="Оценка" source="rate" sortable={true} />
      </Datagrid>
    </List>
  );
};
