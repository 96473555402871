import { FC } from 'react';

import styles from './styles.module.css';

type ModalProps = {
  isVisible: boolean;
  close: () => void;
  confirmButton: () => void;
  title: string;
  text: string;
};
export const Modal: FC<ModalProps> = ({ isVisible, title, text, confirmButton, close }) => {
  return (
    <>
      {isVisible && (
        <div onClick={(e) => e.currentTarget === e.target && close()} className={styles.root}>
          <div className={styles.body}>
            <div className={styles.wrapper}>
              <h1 className={styles.title}>{title}</h1>
              <p className={styles.text}>{text}</p>

              <div className={styles.buttonsWrapper}>
                <button className={styles.cancelButton} onClick={close}>
                  Отменить
                </button>
                <button className={styles.confirmButton} onClick={confirmButton}>
                  Подтвердить
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
