import React, { FC } from 'react';

import { axiosInterceptors } from './config';

type AxiosProviderProps = {
  children: React.ReactNode;
};

axiosInterceptors.setup();

export const AxiosProvider: FC<AxiosProviderProps> = ({ children }) => {
  return <>{children}</>;
};
