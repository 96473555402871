import React, { FC } from 'react';
import { Admin, DataProvider } from 'react-admin';

import { Auth, DataAccess, I18n } from '@features';

import './styles.css';

type AdminProviderProps = {
  children: React.ReactNode;
};

export const AdminProvider: FC<AdminProviderProps> = ({ children }) => {
  return (
    <Admin
      authProvider={Auth.authProvider}
      dataProvider={DataAccess.dataProvider as DataProvider}
      i18nProvider={I18n.Provider}
      requireAuth={true}
      disableTelemetry={true}
    >
      {children}
    </Admin>
  );
};
