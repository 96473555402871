import { FC } from 'react';
import { BooleanField, DateField, EmailField, Show, SimpleShowLayout, TextField } from 'react-admin';

import { UserReport } from '@features';

export const ShowUserReports: FC = () => {
  return (
    <Show title="Жалоба" sx={{ maxWidth: '100%' }}>
      <SimpleShowLayout>
        <b>Информация о пользователе-отправителе</b>

        <TextField
          label="Имя пользователя"
          source="initiatorUser.nickname"
          emptyText="имя пользователя не указано"
        />
        <TextField label="user Id" source="initiatorUser.id" emptyText="id не указан" />
        <EmailField label="Почта" source="initiatorUser.email" emptyText="почта не указана" />
        <DateField label="Дата создания пользователя" source="initiatorUser.createdAt" locales="ru" />
        <TextField label="Страна" source="initiatorUser.country" emptyText="страна не указана" />
        <TextField label="Язык" source="initiatorUser.language" emptyText="язык не указан" />
        <TextField label="Пол" source="initiatorUser.sex" emptyText="пол не указан" />
        <BooleanField label="Статус стилиста" source="initiatorUser.isStylist" emptyText="статус не указан" />

        <b>Информация об авторе комментария</b>
        <TextField
          label="Имя пользователя"
          source="comment.user.nickname"
          emptyText="имя пользователя не указано"
        />
        <TextField label="user Id" source="comment.user.id" emptyText="id не указан" />
        <EmailField label="Почта" source="comment.user.email" emptyText="почта не указана" />
        <DateField label="Дата создания пользователя" source="comment.user.createdAt" locales="ru" />
        <TextField label="Страна" source="comment.user.country" emptyText="страна не указана" />
        <TextField label="Язык" source="comment.user.language" emptyText="язык не указан" />
        <TextField label="Пол" source="comment.user.sex" emptyText="пол не указан" />
        <BooleanField label="Статус стилиста" source="comment.user.isStylist" emptyText="статус не указан" />

        <b>Информация о жалобе</b>
        <TextField label="report id" source="id" emptyText="id не указан" />
        <DateField label="Дата создания жалобы" source="createdAt" locales="ru" emptyText="дата не указана" />
        <TextField label="Причина" source="reason" emptyText="причина не указана" />
        <TextField label="Статус" source="status" emptyText="статус не указан" />
        <TextField label="Тип" source="reportKind" emptyText="тип не указан" />
        <DateField
          label="Дата создания комментария"
          source="comment.createdAt"
          locales="ru"
          emptyText="дата не указана"
        />
        <TextField label="Описание жалобы" source="reportComment" emptyText="Сообщение не указано" />
        <TextField label="Текст комментария" source="comment.body" emptyText="Сообщение не указано" />

        <UserReport.ApproveAndDeclineButtons />
      </SimpleShowLayout>
    </Show>
  );
};
