import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { BooleanInput, SimpleForm, TextInput } from 'react-admin';
import * as yup from 'yup';

import { Item } from '@entities';

import { FIELD_REQUIRED, MIN_NAME, MIN_PHOTO, MIN_TAG, NOT_URL } from '@shared/constants';
import { onlyOneCoverValidation, onlyOneDefaultValidation } from '@shared/lib';

const schema = yup
  .object()
  .shape({
    itemTypeId: yup.string().required(FIELD_REQUIRED),
    itemTagIds: yup.array().of(yup.string()).min(1, MIN_TAG),
    localizations: yup
      .array()
      .of(
        yup.object().shape({
          language: yup.string().required(FIELD_REQUIRED),
          localization: yup.string().required(FIELD_REQUIRED),
        }),
      )
      .min(1, MIN_NAME)
      .required(FIELD_REQUIRED)
      .test('onlyOneDefault', '', onlyOneDefaultValidation),
    itemPhotos: yup
      .array()
      .of(
        yup.object().shape({
          photoUrl: yup.string().url(NOT_URL).required(FIELD_REQUIRED),
          isCover: yup.boolean().required(FIELD_REQUIRED),
        }),
      )
      .min(1, MIN_PHOTO)
      .required(FIELD_REQUIRED)
      .test('onlyOneCover', '', onlyOneCoverValidation),
    brandLinkKeys: yup.array().of(
      yup.object().shape({
        brandId: yup.string().required(FIELD_REQUIRED),
        link: yup.string().url(NOT_URL).required(FIELD_REQUIRED),
      }),
    ),
  })
  .required();

export const CreateForm: FC = () => {
  const defaultValues = {
    visible: true,
    localizations: [{ language: '', localization: '', isDefault: true }],
    itemPhotos: [{ photoUrl: '', isCover: true }],
    brandLinkKeys: [{ brandId: '', link: '' }],
  };

  return (
    <SimpleForm resolver={yupResolver(schema)} defaultValues={defaultValues}>
      <BooleanInput label="Видимость" name="visible" source="visible" />
      <Item.Inputs.Type label="Тип" name="itemTypeId" source="itemTypeId" />
      <Item.Inputs.Tags label="Теги" name="itemTagIds" source="itemTagIds" />
      <Item.Inputs.Localization label="Название" name="localizations" source="localizations" />
      <Item.Inputs.PhotoUrls label="Ссылки на изображения" name="itemPhotos" source="itemPhotos" />
      <Item.Inputs.BrandLinks label="Ссылки на магазины" name="brandLinkKeys" source="brandLinkKeys" />
      <TextInput label="Бренд" name="companyName" source="companyName" />
    </SimpleForm>
  );
};
