const languageNames = new Intl.DisplayNames(['ru'], {
  type: 'language',
});

export const langs = [
  { id: 'ru', name: languageNames.of('ru') },
  { id: 'en', name: languageNames.of('en') },
  // { id: 'br', name: languageNames.of('br') },
  // { id: 'cy', name: languageNames.of('cy') },
  // { id: 'dv', name: 'Мальдивский' },
  // { id: 'sw', name: languageNames.of('sw') },
  // { id: 'eu', name: languageNames.of('eu') },
  // { id: 'af', name: languageNames.of('af') },
  // { id: 'am', name: languageNames.of('am') },
  // { id: 'ha', name: languageNames.of('ha') },
  // { id: 'ku', name: languageNames.of('ku') },
  // { id: 'ml', name: languageNames.of('ml') },
  // { id: 'no', name: languageNames.of('no') },
  // { id: 'ps', name: languageNames.of('ps') },
  // { id: 'sd', name: languageNames.of('sd') },
  // { id: 'so', name: languageNames.of('so') },
  // { id: 'sq', name: languageNames.of('sq') },
  // { id: 'ta', name: languageNames.of('ta') },
  // { id: 'tg', name: languageNames.of('tg') },
  // { id: 'tt', name: languageNames.of('tt') },
  // { id: 'ug', name: languageNames.of('ug') },
  // { id: 'ur', name: languageNames.of('ur') },
  // { id: 'vi', name: languageNames.of('vi') },
  // { id: 'ar', name: languageNames.of('ar') },
  // { id: 'az', name: languageNames.of('az') },
  // { id: 'be', name: languageNames.of('be') },
  // { id: 'bg', name: languageNames.of('bg') },
  // { id: 'bn', name: languageNames.of('bn') },
  // { id: 'bs', name: languageNames.of('bs') },
  // { id: 'ca', name: languageNames.of('ca') },
  // { id: 'cs', name: languageNames.of('cs') },
  // { id: 'de', name: languageNames.of('de') },
  // { id: 'el', name: languageNames.of('el') },
  // { id: 'es', name: languageNames.of('es') },
  // { id: 'et', name: languageNames.of('et') },
  // { id: 'fa', name: languageNames.of('fa') },
  // { id: 'fi', name: languageNames.of('fi') },
  // { id: 'fr', name: languageNames.of('fr') },
  // { id: 'gl', name: languageNames.of('gl') },
  // { id: 'he', name: languageNames.of('he') },
  // { id: 'hi', name: languageNames.of('hi') },
  // { id: 'hr', name: languageNames.of('hr') },
  // { id: 'hu', name: languageNames.of('hu') },
  // { id: 'hy', name: languageNames.of('hy') },
  // { id: 'id', name: languageNames.of('id') },
  // { id: 'is', name: languageNames.of('is') },
  // { id: 'it', name: languageNames.of('it') },
  // { id: 'ja', name: languageNames.of('ja') },
  // { id: 'ka', name: languageNames.of('ka') },
  // { id: 'kk', name: languageNames.of('kk') },
  // { id: 'km', name: languageNames.of('km') },
  // { id: 'ko', name: languageNames.of('ko') },
  // { id: 'ky', name: languageNames.of('ky') },
  // { id: 'lt', name: languageNames.of('lt') },
  // { id: 'lv', name: languageNames.of('lv') },
  // { id: 'mk', name: languageNames.of('mk') },
  // { id: 'mn', name: languageNames.of('mn') },
  // { id: 'mr', name: languageNames.of('mr') },
  // { id: 'ms', name: languageNames.of('ms') },
  // { id: 'nb', name: languageNames.of('nb') },
  // { id: 'nl', name: languageNames.of('nl') },
  // { id: 'nn', name: languageNames.of('nn') },
  // { id: 'pl', name: languageNames.of('pl') },
  // { id: 'pt', name: languageNames.of('pt') },
  // { id: 'ro', name: languageNames.of('ro') },
  // { id: 'sk', name: languageNames.of('sk') },
  // { id: 'sl', name: languageNames.of('sl') },
  // { id: 'sr', name: languageNames.of('sr') },
  // { id: 'sv', name: languageNames.of('sv') },
  // { id: 'th', name: languageNames.of('th') },
  // { id: 'tr', name: languageNames.of('tr') },
  // { id: 'uk', name: languageNames.of('uk') },
  // { id: 'uz', name: languageNames.of('uz') },
  // { id: 'zh', name: languageNames.of('zh') },
];
