import ItemIcon from '@mui/icons-material/Checkroom';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import UserIcon from '@mui/icons-material/People';
import { Card, CardContent, CardHeader } from '@mui/material';
import { FC } from 'react';

import { Statistic } from '@entities';

import { useStatistic } from '../model';
import styles from './styles.module.css';

export const StatisticList: FC = () => {
  const { statistic } = useStatistic();

  const statisticCard = [
    {
      Icon: <ItemIcon className={styles.icon} />,
      statistic: statistic?.totalAvailableLooks,
      text: 'Всего доступных образов',
    },
    {
      Icon: <GroupAddIcon className={styles.icon} />,
      statistic: statistic?.totalUsersCreatedPerLastMonth,
      text: 'Пользователи созданные в прошлом месяце',
    },
    {
      Icon: <GroupAddIcon className={styles.icon} />,
      statistic: statistic?.totalUsersCreatedPerLastWeek,
      text: 'Пользователи созданные за последнюю неделю',
    },
    {
      Icon: <UserIcon className={styles.icon} />,
      statistic: statistic?.totalUsersHaveOneLook,
      text: 'Всего у пользователей есть один образ',
    },
  ];

  return (
    <>
      <CardHeader title="Статистика" />
      <Card className={styles.root}>
        <CardContent className={styles.wrapper}>
          {statisticCard.map((stat, index) => (
            <Statistic.UI key={index} Icon={stat.Icon} text={stat.text} statistic={stat.statistic} />
          ))}
        </CardContent>
      </Card>
    </>
  );
};
