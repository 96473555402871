/* tslint:disable */
/* eslint-disable */
/**
 * MODUSE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminLookResponseDto } from '../models';
// @ts-ignore
import { AdminPaginatedLooksProfileResponseDto } from '../models';
/**
 * V1AdminLooksApi - axios parameter creator
 * @export
 */
export const V1AdminLooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get look by ID
         * @param {string} lookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLooksControllerGetLookById: async (lookId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lookId' is not null or undefined
            assertParamExists('adminLooksControllerGetLookById', 'lookId', lookId)
            const localVarPath = `/v1/admin/looks/{lookId}`
                .replace(`{${"lookId"}}`, encodeURIComponent(String(lookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paginated looks with user, items and tags list
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [filter] 
         * @param {'asc' | 'desc'} [sortOrder] 
         * @param {Array<string>} [tagIds] 
         * @param {'nickname' | 'createdAt' | 'savingsCount' | 'countByReaction'} [sortField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLooksControllerGetLooksList: async (limit?: number, page?: number, filter?: string, sortOrder?: 'asc' | 'desc', tagIds?: Array<string>, sortField?: 'nickname' | 'createdAt' | 'savingsCount' | 'countByReaction', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/looks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (tagIds) {
                localVarQueryParameter['tagIds'] = Array.from(tagIds);
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminLooksApi - functional programming interface
 * @export
 */
export const V1AdminLooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminLooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get look by ID
         * @param {string} lookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLooksControllerGetLookById(lookId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminLookResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLooksControllerGetLookById(lookId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get paginated looks with user, items and tags list
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [filter] 
         * @param {'asc' | 'desc'} [sortOrder] 
         * @param {Array<string>} [tagIds] 
         * @param {'nickname' | 'createdAt' | 'savingsCount' | 'countByReaction'} [sortField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLooksControllerGetLooksList(limit?: number, page?: number, filter?: string, sortOrder?: 'asc' | 'desc', tagIds?: Array<string>, sortField?: 'nickname' | 'createdAt' | 'savingsCount' | 'countByReaction', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedLooksProfileResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLooksControllerGetLooksList(limit, page, filter, sortOrder, tagIds, sortField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminLooksApi - factory interface
 * @export
 */
export const V1AdminLooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminLooksApiFp(configuration)
    return {
        /**
         * 
         * @summary Get look by ID
         * @param {V1AdminLooksApiAdminLooksControllerGetLookByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLooksControllerGetLookById(requestParameters: V1AdminLooksApiAdminLooksControllerGetLookByIdRequest, options?: AxiosRequestConfig): AxiosPromise<AdminLookResponseDto> {
            return localVarFp.adminLooksControllerGetLookById(requestParameters.lookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paginated looks with user, items and tags list
         * @param {V1AdminLooksApiAdminLooksControllerGetLooksListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLooksControllerGetLooksList(requestParameters: V1AdminLooksApiAdminLooksControllerGetLooksListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminPaginatedLooksProfileResponseDto> {
            return localVarFp.adminLooksControllerGetLooksList(requestParameters.limit, requestParameters.page, requestParameters.filter, requestParameters.sortOrder, requestParameters.tagIds, requestParameters.sortField, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminLooksControllerGetLookById operation in V1AdminLooksApi.
 * @export
 * @interface V1AdminLooksApiAdminLooksControllerGetLookByIdRequest
 */
export interface V1AdminLooksApiAdminLooksControllerGetLookByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof V1AdminLooksApiAdminLooksControllerGetLookById
     */
    readonly lookId: string
}

/**
 * Request parameters for adminLooksControllerGetLooksList operation in V1AdminLooksApi.
 * @export
 * @interface V1AdminLooksApiAdminLooksControllerGetLooksListRequest
 */
export interface V1AdminLooksApiAdminLooksControllerGetLooksListRequest {
    /**
     * 
     * @type {number}
     * @memberof V1AdminLooksApiAdminLooksControllerGetLooksList
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof V1AdminLooksApiAdminLooksControllerGetLooksList
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof V1AdminLooksApiAdminLooksControllerGetLooksList
     */
    readonly filter?: string

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof V1AdminLooksApiAdminLooksControllerGetLooksList
     */
    readonly sortOrder?: 'asc' | 'desc'

    /**
     * 
     * @type {Array<string>}
     * @memberof V1AdminLooksApiAdminLooksControllerGetLooksList
     */
    readonly tagIds?: Array<string>

    /**
     * 
     * @type {'nickname' | 'createdAt' | 'savingsCount' | 'countByReaction'}
     * @memberof V1AdminLooksApiAdminLooksControllerGetLooksList
     */
    readonly sortField?: 'nickname' | 'createdAt' | 'savingsCount' | 'countByReaction'
}

/**
 * V1AdminLooksApi - object-oriented interface
 * @export
 * @class V1AdminLooksApi
 * @extends {BaseAPI}
 */
export class V1AdminLooksApi extends BaseAPI {
    /**
     * 
     * @summary Get look by ID
     * @param {V1AdminLooksApiAdminLooksControllerGetLookByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminLooksApi
     */
    public adminLooksControllerGetLookById(requestParameters: V1AdminLooksApiAdminLooksControllerGetLookByIdRequest, options?: AxiosRequestConfig) {
        return V1AdminLooksApiFp(this.configuration).adminLooksControllerGetLookById(requestParameters.lookId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paginated looks with user, items and tags list
     * @param {V1AdminLooksApiAdminLooksControllerGetLooksListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminLooksApi
     */
    public adminLooksControllerGetLooksList(requestParameters: V1AdminLooksApiAdminLooksControllerGetLooksListRequest = {}, options?: AxiosRequestConfig) {
        return V1AdminLooksApiFp(this.configuration).adminLooksControllerGetLooksList(requestParameters.limit, requestParameters.page, requestParameters.filter, requestParameters.sortOrder, requestParameters.tagIds, requestParameters.sortField, options).then((request) => request(this.axios, this.basePath));
    }
}
