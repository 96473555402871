import * as yup from 'yup';

export const onlyOneCoverValidation = (
  photos: { photoUrl: string; isCover: NonNullable<boolean | undefined> }[],
) => {
  let trueCount = 0;

  for (const photo of photos) {
    if (photo.isCover) {
      trueCount = trueCount + 1;
    }
  }

  if (trueCount === 0) {
    return new yup.ValidationError('Нужно выбрать основное изображение', null, 'itemPhotos');
  }

  if (trueCount > 1) {
    return new yup.ValidationError('Только одно изображение может быть основным', null, 'itemPhotos');
  }

  return true;
};
