import { FC } from 'react';
import { BooleanField, Datagrid, DateField, EmailField, List, TextField } from 'react-admin';

export const FeedbackList: FC = () => {
  return (
    <List title="Обратная связь">
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField label="Имя пользователя" source="user.nickname" sortable={false} />
        <TextField label="Имя пользователя указанное в обратной связи" source="name" sortable={false} />
        <EmailField label="Почта" source="user.email" sortable={false} />
        <DateField source="createdAt" label="Дата создания" sortable={false} locales="ru" />
        <BooleanField label="Обработанно" source="isChecked" sortable={false} />
      </Datagrid>
    </List>
  );
};
