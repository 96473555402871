import { FC } from 'react';
import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  ImageField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
} from 'react-admin';

import { Item } from '@entities';

import styles from './styles.module.css';

export const ShowItem: FC = () => {
  const columnStyles = { '& .RaDatagrid-rowCell': { width: '50%' } };
  const columnStyles_3 = { '& .RaDatagrid-rowCell': { width: '33%' } };

  return (
    <Show title="Просмотр вещи" sx={{ maxWidth: '100%' }}>
      <SimpleShowLayout>
        <TextField label="id" source="id" />
        <DateField label="Дата создания" source="createdAt" locales="ru" />
        <DateField label="Дата обновления" source="updatedAt" locales="ru" />
        <Item.Fields.Visible label="Видимость" />

        <ArrayField label="Изображения" source="photos" className={styles.item}>
          <Datagrid bulkActionButtons={false} sx={columnStyles_3} style={{ maxWidth: '100%' }}>
            <UrlField
              label="Ссылка"
              source="photoUrl"
              style={{ maxWidth: '500px', display: 'block', overflow: 'scroll' }}
            />
            <ImageField source="photoUrl" label="Изображение" />
            <BooleanField source="isCover" label="Основное" />
          </Datagrid>
        </ArrayField>

        <ArrayField label="Название" source="localizations" className={styles.item}>
          <Datagrid bulkActionButtons={false} sx={columnStyles}>
            <Item.Fields.Language label="Язык" source="language" />
            <TextField label="Перевод" source="localization" />
          </Datagrid>
        </ArrayField>

        <ArrayField label="Тип" source="itemType.localizations" className={styles.item}>
          <Datagrid bulkActionButtons={false} sx={columnStyles}>
            <Item.Fields.Language label="Язык" source="language" />
            <TextField label="Перевод" source="localization" />
          </Datagrid>
        </ArrayField>

        <ArrayField label="Ссылки на магазины" source="links" className={styles.item}>
          <Datagrid bulkActionButtons={false} sx={columnStyles}>
            <TextField label="Магазин" source="brand.name" />
            <UrlField label="Ссылка" source="link" />
          </Datagrid>
        </ArrayField>

        <TextField className={styles.companyName} label="Бренд" source="companyName" />

        <ArrayField label="Теги" source="tags" className={styles.item}>
          <Datagrid bulkActionButtons={false} sx={columnStyles}>
            <TextField label="Группа" source="group" />

            <ArrayField label="Название тега" source="localizations">
              <Datagrid bulkActionButtons={false} sx={columnStyles}>
                <Item.Fields.Language label="Язык" source="language" />
                <ChipField label="Перевод" source="localization" />
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
