import { FC } from 'react';
import { BooleanField, Datagrid, DateField, EmailField, List, SearchInput, TextField } from 'react-admin';

export const UserList: FC = () => {
  return (
    <List
      title="Список Пользователей"
      filters={[<SearchInput name="" key="search_filter" source="filter" alwaysOn={true} />]}
      filter={{ nickname: undefined }}
      sort={{ field: 'nickname', order: 'asc' }}
      perPage={20}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField label="Имя пользователя" source="nickname" sortable={true} />
        <EmailField label="Почта" source="email" sortable={true} />
        <DateField source="createdAt" label="Дата создания" sortable={true} locales="ru" />
        <BooleanField label="Подписка" source="hasSubscription" sortable={false} />
      </Datagrid>
    </List>
  );
};
