import React, { FC } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  SimpleFormIterator,
  TextInput,
  required,
  useGetList,
} from 'react-admin';

import { AdminItemBrandResponseDto } from '@shared/api';
import { FIELD_REQUIRED } from '@shared/constants';
import { ResourceName } from '@shared/types';

type BrandLinksInputProps = {
  label: string;
  name: string;
  source: string;
};

export const BrandLinksInput: FC<BrandLinksInputProps> = ({ label, source, name }) => {
  //TODO доделать пагинацию

  const { data, isLoading } = useGetList<AdminItemBrandResponseDto>(ResourceName.ItemBrand, {
    pagination: { page: 1, perPage: 1000 },
  });

  return (
    <ArrayInput source={source} name={name} label={label}>
      <SimpleFormIterator inline={true} disableReordering={true} disableClear={true} fullWidth={true}>
        <AutocompleteInput
          source="brandId"
          choices={data || []}
          isLoading={isLoading}
          label="Магазин"
          sx={{ width: '48%' }}
          validate={required(FIELD_REQUIRED)}
        />

        <TextInput source="link" label="Ссылка" validate={required(FIELD_REQUIRED)} sx={{ width: '48%' }} />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
