import {
  V1AdminAuthApi,
  V1AdminItemTypesApi,
  V1AdminItemsApi,
  V1AdminLooksApi,
  V1AdminStatisticsApi,
  V1AdminSystemSettingsApi,
  V1AdminTagsApi,
  V1AdminUserAssessmentsApi,
  V1AdminUserFeedbacksApi,
  V1AdminUserReportsApi,
  V1AdminUsersApi,
} from './generated/api';
import { httpClient as client } from './http-client';

export const api = {
  auth: new V1AdminAuthApi(undefined, process.env.REACT_APP_API_BASE_URL, client),
  users: new V1AdminUsersApi(undefined, process.env.REACT_APP_API_BASE_URL, client),
  items: new V1AdminItemsApi(undefined, process.env.REACT_APP_API_BASE_URL, client),
  itemTypes: new V1AdminItemTypesApi(undefined, process.env.REACT_APP_API_BASE_URL, client),
  feedback: new V1AdminUserFeedbacksApi(undefined, process.env.REACT_APP_API_BASE_URL, client),
  tags: new V1AdminTagsApi(undefined, process.env.REACT_APP_API_BASE_URL, client),
  statistic: new V1AdminStatisticsApi(undefined, process.env.REACT_APP_API_BASE_URL, client),
  outfits: new V1AdminLooksApi(undefined, process.env.REACT_APP_API_BASE_URL, client),
  settings: new V1AdminSystemSettingsApi(undefined, process.env.REACT_APP_API_BASE_URL, client),
  userAssessments: new V1AdminUserAssessmentsApi(undefined, process.env.REACT_APP_API_BASE_URL, client),
  userReports: new V1AdminUserReportsApi(undefined, process.env.REACT_APP_API_BASE_URL, client),
};

export const httpClient = client;

export * from './generated';
