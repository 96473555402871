/* tslint:disable */
/* eslint-disable */
/**
 * MODUSE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminPaginatedUserReportsResponseDto } from '../models';
// @ts-ignore
import { AdminUserReportResponseDto } from '../models';
/**
 * V1AdminUserReportsApi - axios parameter creator
 * @export
 */
export const V1AdminUserReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Approve user report and remove comment
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserReportsControllerApproveUserReport: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('adminUserReportsControllerApproveUserReport', 'reportId', reportId)
            const localVarPath = `/v1/admin/user-reports/{reportId}/approve`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Declined user report
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserReportsControllerDeclineUserReport: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('adminUserReportsControllerDeclineUserReport', 'reportId', reportId)
            const localVarPath = `/v1/admin/user-reports/{reportId}/decline`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user report by ID
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserReportsControllerGetUserReportById: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('adminUserReportsControllerGetUserReportById', 'reportId', reportId)
            const localVarPath = `/v1/admin/user-reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paginated user reports
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {'pending' | 'approved' | 'declined'} [status] 
         * @param {'user' | 'comment'} [reportKind] 
         * @param {string} [initiatorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserReportsControllerGetUserReportsList: async (limit?: number, page?: number, status?: 'pending' | 'approved' | 'declined', reportKind?: 'user' | 'comment', initiatorId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/user-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (reportKind !== undefined) {
                localVarQueryParameter['reportKind'] = reportKind;
            }

            if (initiatorId !== undefined) {
                localVarQueryParameter['initiatorId'] = initiatorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminUserReportsApi - functional programming interface
 * @export
 */
export const V1AdminUserReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminUserReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Approve user report and remove comment
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserReportsControllerApproveUserReport(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserReportResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserReportsControllerApproveUserReport(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Declined user report
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserReportsControllerDeclineUserReport(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserReportResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserReportsControllerDeclineUserReport(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user report by ID
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserReportsControllerGetUserReportById(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserReportResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserReportsControllerGetUserReportById(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get paginated user reports
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {'pending' | 'approved' | 'declined'} [status] 
         * @param {'user' | 'comment'} [reportKind] 
         * @param {string} [initiatorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserReportsControllerGetUserReportsList(limit?: number, page?: number, status?: 'pending' | 'approved' | 'declined', reportKind?: 'user' | 'comment', initiatorId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedUserReportsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserReportsControllerGetUserReportsList(limit, page, status, reportKind, initiatorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminUserReportsApi - factory interface
 * @export
 */
export const V1AdminUserReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminUserReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Approve user report and remove comment
         * @param {V1AdminUserReportsApiAdminUserReportsControllerApproveUserReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserReportsControllerApproveUserReport(requestParameters: V1AdminUserReportsApiAdminUserReportsControllerApproveUserReportRequest, options?: AxiosRequestConfig): AxiosPromise<AdminUserReportResponseDto> {
            return localVarFp.adminUserReportsControllerApproveUserReport(requestParameters.reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Declined user report
         * @param {V1AdminUserReportsApiAdminUserReportsControllerDeclineUserReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserReportsControllerDeclineUserReport(requestParameters: V1AdminUserReportsApiAdminUserReportsControllerDeclineUserReportRequest, options?: AxiosRequestConfig): AxiosPromise<AdminUserReportResponseDto> {
            return localVarFp.adminUserReportsControllerDeclineUserReport(requestParameters.reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user report by ID
         * @param {V1AdminUserReportsApiAdminUserReportsControllerGetUserReportByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserReportsControllerGetUserReportById(requestParameters: V1AdminUserReportsApiAdminUserReportsControllerGetUserReportByIdRequest, options?: AxiosRequestConfig): AxiosPromise<AdminUserReportResponseDto> {
            return localVarFp.adminUserReportsControllerGetUserReportById(requestParameters.reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paginated user reports
         * @param {V1AdminUserReportsApiAdminUserReportsControllerGetUserReportsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserReportsControllerGetUserReportsList(requestParameters: V1AdminUserReportsApiAdminUserReportsControllerGetUserReportsListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminPaginatedUserReportsResponseDto> {
            return localVarFp.adminUserReportsControllerGetUserReportsList(requestParameters.limit, requestParameters.page, requestParameters.status, requestParameters.reportKind, requestParameters.initiatorId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminUserReportsControllerApproveUserReport operation in V1AdminUserReportsApi.
 * @export
 * @interface V1AdminUserReportsApiAdminUserReportsControllerApproveUserReportRequest
 */
export interface V1AdminUserReportsApiAdminUserReportsControllerApproveUserReportRequest {
    /**
     * 
     * @type {string}
     * @memberof V1AdminUserReportsApiAdminUserReportsControllerApproveUserReport
     */
    readonly reportId: string
}

/**
 * Request parameters for adminUserReportsControllerDeclineUserReport operation in V1AdminUserReportsApi.
 * @export
 * @interface V1AdminUserReportsApiAdminUserReportsControllerDeclineUserReportRequest
 */
export interface V1AdminUserReportsApiAdminUserReportsControllerDeclineUserReportRequest {
    /**
     * 
     * @type {string}
     * @memberof V1AdminUserReportsApiAdminUserReportsControllerDeclineUserReport
     */
    readonly reportId: string
}

/**
 * Request parameters for adminUserReportsControllerGetUserReportById operation in V1AdminUserReportsApi.
 * @export
 * @interface V1AdminUserReportsApiAdminUserReportsControllerGetUserReportByIdRequest
 */
export interface V1AdminUserReportsApiAdminUserReportsControllerGetUserReportByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof V1AdminUserReportsApiAdminUserReportsControllerGetUserReportById
     */
    readonly reportId: string
}

/**
 * Request parameters for adminUserReportsControllerGetUserReportsList operation in V1AdminUserReportsApi.
 * @export
 * @interface V1AdminUserReportsApiAdminUserReportsControllerGetUserReportsListRequest
 */
export interface V1AdminUserReportsApiAdminUserReportsControllerGetUserReportsListRequest {
    /**
     * 
     * @type {number}
     * @memberof V1AdminUserReportsApiAdminUserReportsControllerGetUserReportsList
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof V1AdminUserReportsApiAdminUserReportsControllerGetUserReportsList
     */
    readonly page?: number

    /**
     * 
     * @type {'pending' | 'approved' | 'declined'}
     * @memberof V1AdminUserReportsApiAdminUserReportsControllerGetUserReportsList
     */
    readonly status?: 'pending' | 'approved' | 'declined'

    /**
     * 
     * @type {'user' | 'comment'}
     * @memberof V1AdminUserReportsApiAdminUserReportsControllerGetUserReportsList
     */
    readonly reportKind?: 'user' | 'comment'

    /**
     * 
     * @type {string}
     * @memberof V1AdminUserReportsApiAdminUserReportsControllerGetUserReportsList
     */
    readonly initiatorId?: string
}

/**
 * V1AdminUserReportsApi - object-oriented interface
 * @export
 * @class V1AdminUserReportsApi
 * @extends {BaseAPI}
 */
export class V1AdminUserReportsApi extends BaseAPI {
    /**
     * 
     * @summary Approve user report and remove comment
     * @param {V1AdminUserReportsApiAdminUserReportsControllerApproveUserReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUserReportsApi
     */
    public adminUserReportsControllerApproveUserReport(requestParameters: V1AdminUserReportsApiAdminUserReportsControllerApproveUserReportRequest, options?: AxiosRequestConfig) {
        return V1AdminUserReportsApiFp(this.configuration).adminUserReportsControllerApproveUserReport(requestParameters.reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Declined user report
     * @param {V1AdminUserReportsApiAdminUserReportsControllerDeclineUserReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUserReportsApi
     */
    public adminUserReportsControllerDeclineUserReport(requestParameters: V1AdminUserReportsApiAdminUserReportsControllerDeclineUserReportRequest, options?: AxiosRequestConfig) {
        return V1AdminUserReportsApiFp(this.configuration).adminUserReportsControllerDeclineUserReport(requestParameters.reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user report by ID
     * @param {V1AdminUserReportsApiAdminUserReportsControllerGetUserReportByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUserReportsApi
     */
    public adminUserReportsControllerGetUserReportById(requestParameters: V1AdminUserReportsApiAdminUserReportsControllerGetUserReportByIdRequest, options?: AxiosRequestConfig) {
        return V1AdminUserReportsApiFp(this.configuration).adminUserReportsControllerGetUserReportById(requestParameters.reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paginated user reports
     * @param {V1AdminUserReportsApiAdminUserReportsControllerGetUserReportsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUserReportsApi
     */
    public adminUserReportsControllerGetUserReportsList(requestParameters: V1AdminUserReportsApiAdminUserReportsControllerGetUserReportsListRequest = {}, options?: AxiosRequestConfig) {
        return V1AdminUserReportsApiFp(this.configuration).adminUserReportsControllerGetUserReportsList(requestParameters.limit, requestParameters.page, requestParameters.status, requestParameters.reportKind, requestParameters.initiatorId, options).then((request) => request(this.axios, this.basePath));
    }
}
