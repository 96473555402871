import { FC } from 'react';
import { BooleanField, useRecordContext } from 'react-admin';

import { AdminItemResponseDto } from '@shared/api';

type VisibleFieldProps = {
  label: string;
};

export const VisibleField: FC<VisibleFieldProps> = ({ label }) => {
  const record = useRecordContext<AdminItemResponseDto>();

  return <BooleanField label={label} source="visible" record={{ visible: !record.deletedAt }} />;
};
