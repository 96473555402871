import { FC } from 'react';
import { AutocompleteArrayInput, useGetList } from 'react-admin';

import { AdminTagResponseDto } from '@shared/api';
import { ResourceName } from '@shared/types';

type TagFilterProps = {
  label: string;
  name: string;
  source: string;
  alwaysOn: boolean;
};

export const TagFilter: FC<TagFilterProps> = ({ label, name, source, alwaysOn }) => {
  // TODO доделать пагинацию

  const { data, isLoading } = useGetList<AdminTagResponseDto>(ResourceName.Tag, {
    pagination: { page: 1, perPage: 1000 },
  });

  const choices = data?.map((itemTag) => {
    const ruLocalization = itemTag.localizations.find((localization) => localization.language === 'ru');

    return { id: itemTag.id, name: ruLocalization?.localization || itemTag.localizations[0]?.localization };
  });

  return (
    <AutocompleteArrayInput
      name={name}
      label={label}
      source={source}
      isLoading={isLoading}
      choices={choices || []}
      alwaysOn={alwaysOn}
    />
  );
};
