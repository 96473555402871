import { FC } from 'react';
import { TextField, useRecordContext } from 'react-admin';

import { AdminItemResponseDto } from '@shared/api';

type LocalizationFieldProps = {
  label: string;
};

export const LocalizationField: FC<LocalizationFieldProps> = () => {
  const record = useRecordContext<AdminItemResponseDto>();

  const ruLocalization = record.localizations.find((localization) => localization.language === 'ru');

  return (
    <TextField
      source="name"
      sortable={false}
      record={{ name: ruLocalization?.localization || record.localizations[0]?.localization }}
    />
  );
};
