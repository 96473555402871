import { Button } from '@mui/material';
import { FC } from 'react';
import { useNotify, useRecordContext, useRefresh } from 'react-admin';

import { AdminUserReportResponseDto, api } from '@shared/api';

export const ApproveAndDeclineButtons: FC = () => {
  const record = useRecordContext<AdminUserReportResponseDto>();
  const refresh = useRefresh();
  const notify = useNotify();

  const approve = async () => {
    try {
      await api.userReports.adminUserReportsControllerApproveUserReport({
        reportId: record.id,
      });
    } catch (error) {
      notify(error as string, { type: 'error' });
    } finally {
      refresh();
    }
  };

  const decline = async () => {
    try {
      await api.userReports.adminUserReportsControllerDeclineUserReport({
        reportId: record.id,
      });
    } catch (error) {
      notify(error as string, { type: 'error' });
    } finally {
      refresh();
    }
  };

  return (
    <>
      <Button variant="contained" color="success" disabled={record.status !== 'pending'} onClick={approve}>
        Одобрить жалобу
      </Button>

      <Button
        style={{ marginLeft: '10px' }}
        variant="contained"
        color="error"
        disabled={record.status !== 'pending'}
        onClick={decline}
      >
        Отклонить жалобу
      </Button>
    </>
  );
};
