import { useEffect, useState } from 'react';

import { GetStatisticsResponseDto, api } from '@shared/api';

export const useStatistic = () => {
  const [statistic, setStatistic] = useState<GetStatisticsResponseDto | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.statistic.adminStatisticsControllerGetStatistics();

      setStatistic(response.data);
    };

    fetchData();
  }, []);

  return { statistic };
};
