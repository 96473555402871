import { FC } from 'react';
import { Datagrid, DateField, List, TextField } from 'react-admin';

import { Outfit } from '@entities';

export const OutfitList: FC = () => {
  return (
    <List title="Образы" filters={Outfit.Filters} sort={{ field: 'createdAt', order: 'desc' }} perPage={20}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField label="id" source="id" sortable={false} />
        <TextField label="Имя пользователя" source="user.nickname" sortBy="nickname" sortable={true} />
        <DateField source="createdAt" label="Дата создания" sortable={true} locales="ru" />
        <TextField label="Количество сохранений" source="savingsCount" sortable={true} />
        <TextField
          label="Количество реакций"
          source="reactionsCount"
          sortBy="countByReaction"
          sortable={true}
        />
      </Datagrid>
    </List>
  );
};
