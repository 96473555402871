import * as yup from 'yup';

export const onlyOneDefaultValidation = (
  localizations: { isDefault?: false; language: string; localization: string }[],
) => {
  let trueCount = 0;

  for (const localization of localizations) {
    if (localization.isDefault) {
      trueCount = trueCount + 1;
    }
  }

  if (trueCount === 0) {
    return new yup.ValidationError('Нужно выбрать основную локализацию', null, 'localizations');
  }

  if (trueCount > 1) {
    return new yup.ValidationError('Только одна локализация может быть основной', null, 'localizations');
  }

  return true;
};
