import { FC, useState } from 'react';
import { Button, useNotify, useRecordContext, useRefresh } from 'react-admin';

import { AdminUserFeedbackResponseDto, api } from '@shared/api';

export const ToggleChecked: FC = () => {
  const record = useRecordContext<AdminUserFeedbackResponseDto>();
  const refresh = useRefresh();
  const notify = useNotify();

  const [isLoading, setIsLoading] = useState(false);

  const toggleChecked = async () => {
    try {
      setIsLoading(true);
      await api.feedback.adminUserFeedbacksControllerUpdateUserFeedback({
        id: record.id,
        adminUpdateUserFeedbackDto: { isChecked: !record.isChecked },
      });
    } catch (error) {
      notify(error as string, { type: 'error' });
    } finally {
      refresh();
      setIsLoading(false);
    }
  };

  return (
    <Button
      disabled={isLoading}
      label={record.isChecked ? 'Отметить как необработанное' : 'Отметить как обработанное'}
      onClick={toggleChecked}
    />
  );
};
