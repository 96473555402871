import { FC } from 'react';
import { BooleanField, DateField, EmailField, Show, SimpleShowLayout, TextField } from 'react-admin';

export const ShowUserAssessments: FC = () => {
  return (
    <Show title="Обратная связь" sx={{ maxWidth: '100%' }}>
      <SimpleShowLayout>
        <TextField label="Имя пользователя" source="user.nickname" />

        <TextField label="id" source="id" />
        <TextField label="user Id" source="userId" />
        <TextField label="Оценка" source="rate" />
        <EmailField label="Почта" source="user.email" />
        <DateField label="Дата создания" source="createdAt" locales="ru" />
        <DateField label="Дата создания пользователя" source="user.createdAt" locales="ru" />
        <TextField label="Страна" source="user.country" />
        <TextField label="Язык" source="user.language" />
        <TextField label="Пол" source="user.sex" emptyText="пол не указан" />
        <BooleanField label="Стилист" source="user.isStylist" />

        <TextField label="Сообщение" source="message" emptyText="Сообщение не указано" />
      </SimpleShowLayout>
    </Show>
  );
};
