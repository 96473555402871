/* tslint:disable */
/* eslint-disable */
/**
 * MODUSE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminCreateItemDto } from '../models';
// @ts-ignore
import { AdminItemResponseDto } from '../models';
// @ts-ignore
import { AdminPaginatedItemBrandsResponseDto } from '../models';
// @ts-ignore
import { AdminPaginatedItemsResponseDto } from '../models';
// @ts-ignore
import { AdminUpdateItemDto } from '../models';
/**
 * V1AdminItemsApi - axios parameter creator
 * @export
 */
export const V1AdminItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new item with localizations, photos and links
         * @param {AdminCreateItemDto} adminCreateItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminItemsControllerCreateItem: async (adminCreateItemDto: AdminCreateItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateItemDto' is not null or undefined
            assertParamExists('adminItemsControllerCreateItem', 'adminCreateItemDto', adminCreateItemDto)
            const localVarPath = `/v1/admin/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paginated item brands
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminItemsControllerGetItemBrandsList: async (limit?: number, page?: number, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/items/brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get item by ID
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminItemsControllerGetItemById: async (itemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('adminItemsControllerGetItemById', 'itemId', itemId)
            const localVarPath = `/v1/admin/items/{itemId}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paginated items with localizations, photos and links list
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [filter] 
         * @param {string} [itemTypeId] 
         * @param {Array<string>} [tagIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminItemsControllerGetItemsList: async (limit?: number, page?: number, filter?: string, itemTypeId?: string, tagIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (itemTypeId !== undefined) {
                localVarQueryParameter['itemTypeId'] = itemTypeId;
            }

            if (tagIds) {
                localVarQueryParameter['tagIds'] = Array.from(tagIds);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update existing item\'s localizations, photos, links and visibility
         * @param {string} itemId 
         * @param {AdminUpdateItemDto} adminUpdateItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminItemsControllerUpdateItem: async (itemId: string, adminUpdateItemDto: AdminUpdateItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('adminItemsControllerUpdateItem', 'itemId', itemId)
            // verify required parameter 'adminUpdateItemDto' is not null or undefined
            assertParamExists('adminItemsControllerUpdateItem', 'adminUpdateItemDto', adminUpdateItemDto)
            const localVarPath = `/v1/admin/items/{itemId}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminItemsApi - functional programming interface
 * @export
 */
export const V1AdminItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new item with localizations, photos and links
         * @param {AdminCreateItemDto} adminCreateItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminItemsControllerCreateItem(adminCreateItemDto: AdminCreateItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminItemResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminItemsControllerCreateItem(adminCreateItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get paginated item brands
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminItemsControllerGetItemBrandsList(limit?: number, page?: number, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedItemBrandsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminItemsControllerGetItemBrandsList(limit, page, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get item by ID
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminItemsControllerGetItemById(itemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminItemResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminItemsControllerGetItemById(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get paginated items with localizations, photos and links list
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [filter] 
         * @param {string} [itemTypeId] 
         * @param {Array<string>} [tagIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminItemsControllerGetItemsList(limit?: number, page?: number, filter?: string, itemTypeId?: string, tagIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedItemsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminItemsControllerGetItemsList(limit, page, filter, itemTypeId, tagIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update existing item\'s localizations, photos, links and visibility
         * @param {string} itemId 
         * @param {AdminUpdateItemDto} adminUpdateItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminItemsControllerUpdateItem(itemId: string, adminUpdateItemDto: AdminUpdateItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminItemResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminItemsControllerUpdateItem(itemId, adminUpdateItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminItemsApi - factory interface
 * @export
 */
export const V1AdminItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminItemsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new item with localizations, photos and links
         * @param {V1AdminItemsApiAdminItemsControllerCreateItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminItemsControllerCreateItem(requestParameters: V1AdminItemsApiAdminItemsControllerCreateItemRequest, options?: AxiosRequestConfig): AxiosPromise<AdminItemResponseDto> {
            return localVarFp.adminItemsControllerCreateItem(requestParameters.adminCreateItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paginated item brands
         * @param {V1AdminItemsApiAdminItemsControllerGetItemBrandsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminItemsControllerGetItemBrandsList(requestParameters: V1AdminItemsApiAdminItemsControllerGetItemBrandsListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminPaginatedItemBrandsResponseDto> {
            return localVarFp.adminItemsControllerGetItemBrandsList(requestParameters.limit, requestParameters.page, requestParameters.filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get item by ID
         * @param {V1AdminItemsApiAdminItemsControllerGetItemByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminItemsControllerGetItemById(requestParameters: V1AdminItemsApiAdminItemsControllerGetItemByIdRequest, options?: AxiosRequestConfig): AxiosPromise<AdminItemResponseDto> {
            return localVarFp.adminItemsControllerGetItemById(requestParameters.itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paginated items with localizations, photos and links list
         * @param {V1AdminItemsApiAdminItemsControllerGetItemsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminItemsControllerGetItemsList(requestParameters: V1AdminItemsApiAdminItemsControllerGetItemsListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminPaginatedItemsResponseDto> {
            return localVarFp.adminItemsControllerGetItemsList(requestParameters.limit, requestParameters.page, requestParameters.filter, requestParameters.itemTypeId, requestParameters.tagIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update existing item\'s localizations, photos, links and visibility
         * @param {V1AdminItemsApiAdminItemsControllerUpdateItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminItemsControllerUpdateItem(requestParameters: V1AdminItemsApiAdminItemsControllerUpdateItemRequest, options?: AxiosRequestConfig): AxiosPromise<AdminItemResponseDto> {
            return localVarFp.adminItemsControllerUpdateItem(requestParameters.itemId, requestParameters.adminUpdateItemDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminItemsControllerCreateItem operation in V1AdminItemsApi.
 * @export
 * @interface V1AdminItemsApiAdminItemsControllerCreateItemRequest
 */
export interface V1AdminItemsApiAdminItemsControllerCreateItemRequest {
    /**
     * 
     * @type {AdminCreateItemDto}
     * @memberof V1AdminItemsApiAdminItemsControllerCreateItem
     */
    readonly adminCreateItemDto: AdminCreateItemDto
}

/**
 * Request parameters for adminItemsControllerGetItemBrandsList operation in V1AdminItemsApi.
 * @export
 * @interface V1AdminItemsApiAdminItemsControllerGetItemBrandsListRequest
 */
export interface V1AdminItemsApiAdminItemsControllerGetItemBrandsListRequest {
    /**
     * 
     * @type {number}
     * @memberof V1AdminItemsApiAdminItemsControllerGetItemBrandsList
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof V1AdminItemsApiAdminItemsControllerGetItemBrandsList
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof V1AdminItemsApiAdminItemsControllerGetItemBrandsList
     */
    readonly filter?: string
}

/**
 * Request parameters for adminItemsControllerGetItemById operation in V1AdminItemsApi.
 * @export
 * @interface V1AdminItemsApiAdminItemsControllerGetItemByIdRequest
 */
export interface V1AdminItemsApiAdminItemsControllerGetItemByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof V1AdminItemsApiAdminItemsControllerGetItemById
     */
    readonly itemId: string
}

/**
 * Request parameters for adminItemsControllerGetItemsList operation in V1AdminItemsApi.
 * @export
 * @interface V1AdminItemsApiAdminItemsControllerGetItemsListRequest
 */
export interface V1AdminItemsApiAdminItemsControllerGetItemsListRequest {
    /**
     * 
     * @type {number}
     * @memberof V1AdminItemsApiAdminItemsControllerGetItemsList
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof V1AdminItemsApiAdminItemsControllerGetItemsList
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof V1AdminItemsApiAdminItemsControllerGetItemsList
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof V1AdminItemsApiAdminItemsControllerGetItemsList
     */
    readonly itemTypeId?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof V1AdminItemsApiAdminItemsControllerGetItemsList
     */
    readonly tagIds?: Array<string>
}

/**
 * Request parameters for adminItemsControllerUpdateItem operation in V1AdminItemsApi.
 * @export
 * @interface V1AdminItemsApiAdminItemsControllerUpdateItemRequest
 */
export interface V1AdminItemsApiAdminItemsControllerUpdateItemRequest {
    /**
     * 
     * @type {string}
     * @memberof V1AdminItemsApiAdminItemsControllerUpdateItem
     */
    readonly itemId: string

    /**
     * 
     * @type {AdminUpdateItemDto}
     * @memberof V1AdminItemsApiAdminItemsControllerUpdateItem
     */
    readonly adminUpdateItemDto: AdminUpdateItemDto
}

/**
 * V1AdminItemsApi - object-oriented interface
 * @export
 * @class V1AdminItemsApi
 * @extends {BaseAPI}
 */
export class V1AdminItemsApi extends BaseAPI {
    /**
     * 
     * @summary Create new item with localizations, photos and links
     * @param {V1AdminItemsApiAdminItemsControllerCreateItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminItemsApi
     */
    public adminItemsControllerCreateItem(requestParameters: V1AdminItemsApiAdminItemsControllerCreateItemRequest, options?: AxiosRequestConfig) {
        return V1AdminItemsApiFp(this.configuration).adminItemsControllerCreateItem(requestParameters.adminCreateItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paginated item brands
     * @param {V1AdminItemsApiAdminItemsControllerGetItemBrandsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminItemsApi
     */
    public adminItemsControllerGetItemBrandsList(requestParameters: V1AdminItemsApiAdminItemsControllerGetItemBrandsListRequest = {}, options?: AxiosRequestConfig) {
        return V1AdminItemsApiFp(this.configuration).adminItemsControllerGetItemBrandsList(requestParameters.limit, requestParameters.page, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get item by ID
     * @param {V1AdminItemsApiAdminItemsControllerGetItemByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminItemsApi
     */
    public adminItemsControllerGetItemById(requestParameters: V1AdminItemsApiAdminItemsControllerGetItemByIdRequest, options?: AxiosRequestConfig) {
        return V1AdminItemsApiFp(this.configuration).adminItemsControllerGetItemById(requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paginated items with localizations, photos and links list
     * @param {V1AdminItemsApiAdminItemsControllerGetItemsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminItemsApi
     */
    public adminItemsControllerGetItemsList(requestParameters: V1AdminItemsApiAdminItemsControllerGetItemsListRequest = {}, options?: AxiosRequestConfig) {
        return V1AdminItemsApiFp(this.configuration).adminItemsControllerGetItemsList(requestParameters.limit, requestParameters.page, requestParameters.filter, requestParameters.itemTypeId, requestParameters.tagIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update existing item\'s localizations, photos, links and visibility
     * @param {V1AdminItemsApiAdminItemsControllerUpdateItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminItemsApi
     */
    public adminItemsControllerUpdateItem(requestParameters: V1AdminItemsApiAdminItemsControllerUpdateItemRequest, options?: AxiosRequestConfig) {
        return V1AdminItemsApiFp(this.configuration).adminItemsControllerUpdateItem(requestParameters.itemId, requestParameters.adminUpdateItemDto, options).then((request) => request(this.axios, this.basePath));
    }
}
