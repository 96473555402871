import { FC } from 'react';
import { Datagrid, DateField, EmailField, List, TextField } from 'react-admin';

export const UserReportsList: FC = () => {
  return (
    <List title="Пользовательские жалобы" sort={{ field: 'rate', order: 'asc' }} perPage={20}>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField label="Имя пользователя" source="initiatorUser.nickname" sortable={false} />
        <EmailField label="Почта" source="initiatorUser.email" sortable={false} />
        <DateField source="createdAt" label="Дата создания" sortable={true} locales="ru" />
        <TextField label="Причина" source="reason" sortable={false} />
        <TextField label="Статус" source="status" sortable={false} />
        <TextField label="Тип" source="reportKind" sortable={false} />
      </Datagrid>
    </List>
  );
};
