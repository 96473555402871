import { FC } from 'react';
import { Create } from 'react-admin';

import { Item } from '@entities';

import { AdminCreateItemDto } from '@shared/api';

export const CreateItem: FC = () => {
  const transform = (data: AdminCreateItemDto) => {
    return {
      ...data,
      itemPhotos: data.itemPhotos?.map((photo) => {
        return {
          photoUrl: photo.photo.photoUrl,
          isCover: photo.isCover,
        };
      }),
    };
  };

  return (
    <Create transform={transform} title="Создание Вещи">
      <Item.Forms.Create />
    </Create>
  );
};
