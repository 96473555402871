import { FC } from 'react';
import { ArrayField, Datagrid, TextField, useRecordContext } from 'react-admin';

export const ReactionField: FC = () => {
  const record = useRecordContext();
  if (!record || !record.countByReaction) return null;

  const reactions = Object.entries(record.countByReaction).map(([reaction, count]) => ({
    reaction,
    count,
  }));

  return (
    <ArrayField source="reactions" record={{ reactions }}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="reaction" label="Реакция" />
        <TextField source="count" label="Количество" />
      </Datagrid>
    </ArrayField>
  );
};
