export enum ResourceName {
  User = 'users',
  Item = 'items',
  Outfit = 'looks',
  Feedback = 'user-feedbacks',
  ItemBrand = 'itemBrands',
  ItemType = 'itemTypes',
  Tag = 'tags',
  UserAssessments = 'user-assessments',
  UserReports = 'user-reports',
}

export type SortOrderType = 'asc' | 'desc';
