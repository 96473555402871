import { SearchInput } from 'react-admin';

import { TagFilter } from './tag/tag-filter';
import { TypeFilter } from './type/type-filter';

export const ItemFilters = [
  <SearchInput name="" key="search_filter" source="filter" alwaysOn={true} />,
  <TypeFilter key="type_filter" label="Тип" name="typeFilter" source="type" alwaysOn={true} />,
  <TagFilter key="tag_filter" label="Тег" name="tagFilter" source="tag" alwaysOn={true} />,
];
