import { FC } from 'react';
import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  ImageField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
} from 'react-admin';

import { Item } from '@entities';

import styles from './styles.module.css';

export const ShowOutfit: FC = () => {
  const columnStyles = { '& .RaDatagrid-rowCell': { width: '50%' } };
  const columnStyles_3 = { '& .RaDatagrid-rowCell': { width: '33%' } };

  return (
    <Show title="Просмотр Образа" sx={{ maxWidth: '100%' }}>
      <SimpleShowLayout>
        <TextField label="id" source="id" />
        <TextField label="user id" source="userId" />
        <TextField label="Имя пользователя" source="user.nickname" sortable={true} />
        <BooleanField label="Стилист" source="user.isStylist" />

        <DateField label="Дата создания" source="createdAt" locales="ru" />
        <DateField label="Дата обновления" source="updatedAt" locales="ru" />
        <TextField label="Количество сохранений" source="savingsCount" sortable={true} />
        <TextField label="Количество реакций" source="reactionsCount" sortable={true} />
        <Item.Fields.Reaction />

        <ImageField source="photoUrl" label="Изображение образа" emptyText="Фото не загружено" />
        <UrlField
          label="Ссылка на изображение"
          source="photoUrl"
          style={{ display: 'block', overflow: 'scroll' }}
        />

        <ArrayField label="Теги образа" source="tags" className={styles.item}>
          <Datagrid bulkActionButtons={false}>
            <ArrayField label="Название" source="localizations" className={styles.item}>
              <Datagrid bulkActionButtons={false} sx={columnStyles}>
                <Item.Fields.Language label="Язык" source="language" />
                <TextField label="Перевод" source="localization" />
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </ArrayField>

        <ArrayField label="Вещи" source="items" className={styles.item}>
          <Datagrid bulkActionButtons={false}>
            <ArrayField label="Изображения" source="photos" className={styles.item}>
              <Datagrid bulkActionButtons={false} sx={columnStyles_3} style={{ maxWidth: '100%' }}>
                <UrlField
                  label="Ссылка"
                  source="photoUrl"
                  style={{ maxWidth: '500px', display: 'block', overflow: 'scroll' }}
                />
                <ImageField source="photoUrl" label="Изображение" />
                <BooleanField source="isCover" label="Основное" />
              </Datagrid>
            </ArrayField>
            <TextField label="Бренд" source="companyName" sortable={true} />
          </Datagrid>
        </ArrayField>

        <ArrayField label="Вещи" source="items" className={styles.item}>
          <Datagrid bulkActionButtons={false}>
            <ArrayField label="Название" source="localizations" className={styles.item}>
              <Datagrid bulkActionButtons={false} sx={columnStyles}>
                <Item.Fields.Language label="Язык" source="language" />
                <TextField label="Перевод" source="localization" />
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </ArrayField>

        <ArrayField label="Вещи" source="items" className={styles.item}>
          <Datagrid bulkActionButtons={false}>
            <ArrayField label="Тип" source="itemType.localizations" className={styles.item}>
              <Datagrid bulkActionButtons={false} sx={columnStyles}>
                <Item.Fields.Language label="Язык" source="language" />
                <TextField label="Перевод" source="localization" />
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </ArrayField>

        <ArrayField label="Вещи" source="items" className={styles.item}>
          <Datagrid bulkActionButtons={false}>
            <ArrayField label="Ссылки на магазины" source="links" className={styles.item}>
              <Datagrid bulkActionButtons={false} sx={columnStyles}>
                <TextField label="Магазин" source="brand.name" />
                <UrlField label="Ссылка" source="link" />
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </ArrayField>

        <ArrayField label="Вещи" source="items" className={styles.item}>
          <Datagrid bulkActionButtons={false}>
            <ArrayField label="Теги" source="tags" className={styles.item}>
              <Datagrid bulkActionButtons={false} sx={columnStyles}>
                <TextField label="Группа" source="group" />

                <ArrayField label="Название тега" source="localizations">
                  <Datagrid bulkActionButtons={false} sx={columnStyles}>
                    <Item.Fields.Language label="Язык" source="language" />
                    <ChipField label="Перевод" source="localization" />
                  </Datagrid>
                </ArrayField>
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
