import { FC, ReactNode } from 'react';

import styles from './styles.module.css';

type StatisticProps = {
  Icon: ReactNode;
  text?: string;
  statistic?: number;
};

export const Statistic: FC<StatisticProps> = ({ Icon, statistic, text }) => {
  return (
    <div className={styles.card}>
      <div className={styles.iconWrapper}>{Icon}</div>
      <p className={styles.text}>{text}</p>
      <p className={styles.statistic}>{statistic}</p>
    </div>
  );
};
