import { FC } from 'react';
import { Datagrid, DateField, List } from 'react-admin';

import { Item } from '@entities';

export const ItemList: FC = () => {
  return (
    <List title="Вещи" filters={Item.Filters} actions={<Item.Actions.ListActions />}>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <Item.Fields.Localization label="Название" />
        <Item.Fields.Type label="Тип" />

        <DateField source="createdAt" label="Дата создания" sortable={false} locales="ru" />
        <Item.Fields.Visible label="Видимость" />
      </Datagrid>
    </List>
  );
};
