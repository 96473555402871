/* tslint:disable */
/* eslint-disable */
/**
 * MODUSE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminSetPaymentsEnabledSystemSettingDto } from '../models';
// @ts-ignore
import { AdminSystemSettingResponseDto } from '../models';
/**
 * V1AdminSystemSettingsApi - axios parameter creator
 * @export
 */
export const V1AdminSystemSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get system setting by kind
         * @param {'payments_enabled'} kind 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSystemSettingsControllerGetSystemSetting: async (kind: 'payments_enabled', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'kind' is not null or undefined
            assertParamExists('adminSystemSettingsControllerGetSystemSetting', 'kind', kind)
            const localVarPath = `/v1/admin/system-settings/{kind}`
                .replace(`{${"kind"}}`, encodeURIComponent(String(kind)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List system settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSystemSettingsControllerListSystemSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/system-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set payments enabled system setting
         * @param {AdminSetPaymentsEnabledSystemSettingDto} adminSetPaymentsEnabledSystemSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSystemSettingsControllerSetPaymentsEnabled: async (adminSetPaymentsEnabledSystemSettingDto: AdminSetPaymentsEnabledSystemSettingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminSetPaymentsEnabledSystemSettingDto' is not null or undefined
            assertParamExists('adminSystemSettingsControllerSetPaymentsEnabled', 'adminSetPaymentsEnabledSystemSettingDto', adminSetPaymentsEnabledSystemSettingDto)
            const localVarPath = `/v1/admin/system-settings/payments-enabled`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSetPaymentsEnabledSystemSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminSystemSettingsApi - functional programming interface
 * @export
 */
export const V1AdminSystemSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminSystemSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get system setting by kind
         * @param {'payments_enabled'} kind 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSystemSettingsControllerGetSystemSetting(kind: 'payments_enabled', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSystemSettingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSystemSettingsControllerGetSystemSetting(kind, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List system settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSystemSettingsControllerListSystemSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminSystemSettingResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSystemSettingsControllerListSystemSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set payments enabled system setting
         * @param {AdminSetPaymentsEnabledSystemSettingDto} adminSetPaymentsEnabledSystemSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSystemSettingsControllerSetPaymentsEnabled(adminSetPaymentsEnabledSystemSettingDto: AdminSetPaymentsEnabledSystemSettingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSystemSettingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSystemSettingsControllerSetPaymentsEnabled(adminSetPaymentsEnabledSystemSettingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminSystemSettingsApi - factory interface
 * @export
 */
export const V1AdminSystemSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminSystemSettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get system setting by kind
         * @param {V1AdminSystemSettingsApiAdminSystemSettingsControllerGetSystemSettingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSystemSettingsControllerGetSystemSetting(requestParameters: V1AdminSystemSettingsApiAdminSystemSettingsControllerGetSystemSettingRequest, options?: AxiosRequestConfig): AxiosPromise<AdminSystemSettingResponseDto> {
            return localVarFp.adminSystemSettingsControllerGetSystemSetting(requestParameters.kind, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List system settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSystemSettingsControllerListSystemSettings(options?: AxiosRequestConfig): AxiosPromise<Array<AdminSystemSettingResponseDto>> {
            return localVarFp.adminSystemSettingsControllerListSystemSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set payments enabled system setting
         * @param {V1AdminSystemSettingsApiAdminSystemSettingsControllerSetPaymentsEnabledRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSystemSettingsControllerSetPaymentsEnabled(requestParameters: V1AdminSystemSettingsApiAdminSystemSettingsControllerSetPaymentsEnabledRequest, options?: AxiosRequestConfig): AxiosPromise<AdminSystemSettingResponseDto> {
            return localVarFp.adminSystemSettingsControllerSetPaymentsEnabled(requestParameters.adminSetPaymentsEnabledSystemSettingDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminSystemSettingsControllerGetSystemSetting operation in V1AdminSystemSettingsApi.
 * @export
 * @interface V1AdminSystemSettingsApiAdminSystemSettingsControllerGetSystemSettingRequest
 */
export interface V1AdminSystemSettingsApiAdminSystemSettingsControllerGetSystemSettingRequest {
    /**
     * 
     * @type {'payments_enabled'}
     * @memberof V1AdminSystemSettingsApiAdminSystemSettingsControllerGetSystemSetting
     */
    readonly kind: 'payments_enabled'
}

/**
 * Request parameters for adminSystemSettingsControllerSetPaymentsEnabled operation in V1AdminSystemSettingsApi.
 * @export
 * @interface V1AdminSystemSettingsApiAdminSystemSettingsControllerSetPaymentsEnabledRequest
 */
export interface V1AdminSystemSettingsApiAdminSystemSettingsControllerSetPaymentsEnabledRequest {
    /**
     * 
     * @type {AdminSetPaymentsEnabledSystemSettingDto}
     * @memberof V1AdminSystemSettingsApiAdminSystemSettingsControllerSetPaymentsEnabled
     */
    readonly adminSetPaymentsEnabledSystemSettingDto: AdminSetPaymentsEnabledSystemSettingDto
}

/**
 * V1AdminSystemSettingsApi - object-oriented interface
 * @export
 * @class V1AdminSystemSettingsApi
 * @extends {BaseAPI}
 */
export class V1AdminSystemSettingsApi extends BaseAPI {
    /**
     * 
     * @summary Get system setting by kind
     * @param {V1AdminSystemSettingsApiAdminSystemSettingsControllerGetSystemSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminSystemSettingsApi
     */
    public adminSystemSettingsControllerGetSystemSetting(requestParameters: V1AdminSystemSettingsApiAdminSystemSettingsControllerGetSystemSettingRequest, options?: AxiosRequestConfig) {
        return V1AdminSystemSettingsApiFp(this.configuration).adminSystemSettingsControllerGetSystemSetting(requestParameters.kind, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List system settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminSystemSettingsApi
     */
    public adminSystemSettingsControllerListSystemSettings(options?: AxiosRequestConfig) {
        return V1AdminSystemSettingsApiFp(this.configuration).adminSystemSettingsControllerListSystemSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set payments enabled system setting
     * @param {V1AdminSystemSettingsApiAdminSystemSettingsControllerSetPaymentsEnabledRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminSystemSettingsApi
     */
    public adminSystemSettingsControllerSetPaymentsEnabled(requestParameters: V1AdminSystemSettingsApiAdminSystemSettingsControllerSetPaymentsEnabledRequest, options?: AxiosRequestConfig) {
        return V1AdminSystemSettingsApiFp(this.configuration).adminSystemSettingsControllerSetPaymentsEnabled(requestParameters.adminSetPaymentsEnabledSystemSettingDto, options).then((request) => request(this.axios, this.basePath));
    }
}
