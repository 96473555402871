import { FC } from 'react';
import { Edit } from 'react-admin';
import { v4 as uuidv4 } from 'uuid';

import { Item } from '@entities';

import { AdminItemResponseDto } from '@shared/api';

export const EditItem: FC = () => {
  const transform = (
    data: AdminItemResponseDto & {
      itemTagIds: Array<string>;
      visible: boolean;
      itemPhotos: AdminItemResponseDto['photos'];
    },
  ) => {
    return {
      itemTypeId: data.itemTypeId,
      localizations: data.localizations.map((localization) => ({
        id: localization.id || uuidv4(),
        language: localization.language,
        localization: localization.localization,
        //@ts-ignore
        isDefault: localization.isDefault,
      })),
      itemTagIds: data.itemTagIds,
      brandLinkKeys: data?.links?.map((link) => ({
        id: link.id || uuidv4(),
        brandId: link.brandId,
        link: link.link,
      })),
      itemPhotos: data.itemPhotos.map((photo) => ({
        id: photo.id || uuidv4(),
        photoUrl: photo.photoUrl,
        isCover: photo.isCover,
      })),
      visible: data.visible,
      companyName: data.companyName,
    };
  };

  return (
    <Edit transform={transform} title="Редактирование вещи">
      <Item.Forms.Edit />
    </Edit>
  );
};
