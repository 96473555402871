/* tslint:disable */
/* eslint-disable */
/**
 * MODUSE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminItemTypeResponseDto } from '../models';
// @ts-ignore
import { AdminPaginatedItemTypesResponseDto } from '../models';
// @ts-ignore
import { AdminUpdateItemTypeDto } from '../models';
/**
 * V1AdminItemTypesApi - axios parameter creator
 * @export
 */
export const V1AdminItemTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get item type by ID
         * @param {string} itemTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminItemTypesControllerGetItemTypeById: async (itemTypeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemTypeId' is not null or undefined
            assertParamExists('adminItemTypesControllerGetItemTypeById', 'itemTypeId', itemTypeId)
            const localVarPath = `/v1/admin/items/types/{itemTypeId}`
                .replace(`{${"itemTypeId"}}`, encodeURIComponent(String(itemTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paginated item types with localizations list
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminItemTypesControllerGetItemTypesList: async (limit?: number, page?: number, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/items/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update localizations and machine names of item type
         * @param {string} itemTypeId 
         * @param {AdminUpdateItemTypeDto} adminUpdateItemTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminItemTypesControllerUpdateItemType: async (itemTypeId: string, adminUpdateItemTypeDto: AdminUpdateItemTypeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemTypeId' is not null or undefined
            assertParamExists('adminItemTypesControllerUpdateItemType', 'itemTypeId', itemTypeId)
            // verify required parameter 'adminUpdateItemTypeDto' is not null or undefined
            assertParamExists('adminItemTypesControllerUpdateItemType', 'adminUpdateItemTypeDto', adminUpdateItemTypeDto)
            const localVarPath = `/v1/admin/items/types/{itemTypeId}`
                .replace(`{${"itemTypeId"}}`, encodeURIComponent(String(itemTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateItemTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminItemTypesApi - functional programming interface
 * @export
 */
export const V1AdminItemTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminItemTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get item type by ID
         * @param {string} itemTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminItemTypesControllerGetItemTypeById(itemTypeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminItemTypeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminItemTypesControllerGetItemTypeById(itemTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get paginated item types with localizations list
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminItemTypesControllerGetItemTypesList(limit?: number, page?: number, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaginatedItemTypesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminItemTypesControllerGetItemTypesList(limit, page, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update localizations and machine names of item type
         * @param {string} itemTypeId 
         * @param {AdminUpdateItemTypeDto} adminUpdateItemTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminItemTypesControllerUpdateItemType(itemTypeId: string, adminUpdateItemTypeDto: AdminUpdateItemTypeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminItemTypeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminItemTypesControllerUpdateItemType(itemTypeId, adminUpdateItemTypeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminItemTypesApi - factory interface
 * @export
 */
export const V1AdminItemTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminItemTypesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get item type by ID
         * @param {V1AdminItemTypesApiAdminItemTypesControllerGetItemTypeByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminItemTypesControllerGetItemTypeById(requestParameters: V1AdminItemTypesApiAdminItemTypesControllerGetItemTypeByIdRequest, options?: AxiosRequestConfig): AxiosPromise<AdminItemTypeResponseDto> {
            return localVarFp.adminItemTypesControllerGetItemTypeById(requestParameters.itemTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paginated item types with localizations list
         * @param {V1AdminItemTypesApiAdminItemTypesControllerGetItemTypesListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminItemTypesControllerGetItemTypesList(requestParameters: V1AdminItemTypesApiAdminItemTypesControllerGetItemTypesListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminPaginatedItemTypesResponseDto> {
            return localVarFp.adminItemTypesControllerGetItemTypesList(requestParameters.limit, requestParameters.page, requestParameters.filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update localizations and machine names of item type
         * @param {V1AdminItemTypesApiAdminItemTypesControllerUpdateItemTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminItemTypesControllerUpdateItemType(requestParameters: V1AdminItemTypesApiAdminItemTypesControllerUpdateItemTypeRequest, options?: AxiosRequestConfig): AxiosPromise<AdminItemTypeResponseDto> {
            return localVarFp.adminItemTypesControllerUpdateItemType(requestParameters.itemTypeId, requestParameters.adminUpdateItemTypeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminItemTypesControllerGetItemTypeById operation in V1AdminItemTypesApi.
 * @export
 * @interface V1AdminItemTypesApiAdminItemTypesControllerGetItemTypeByIdRequest
 */
export interface V1AdminItemTypesApiAdminItemTypesControllerGetItemTypeByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof V1AdminItemTypesApiAdminItemTypesControllerGetItemTypeById
     */
    readonly itemTypeId: string
}

/**
 * Request parameters for adminItemTypesControllerGetItemTypesList operation in V1AdminItemTypesApi.
 * @export
 * @interface V1AdminItemTypesApiAdminItemTypesControllerGetItemTypesListRequest
 */
export interface V1AdminItemTypesApiAdminItemTypesControllerGetItemTypesListRequest {
    /**
     * 
     * @type {number}
     * @memberof V1AdminItemTypesApiAdminItemTypesControllerGetItemTypesList
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof V1AdminItemTypesApiAdminItemTypesControllerGetItemTypesList
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof V1AdminItemTypesApiAdminItemTypesControllerGetItemTypesList
     */
    readonly filter?: string
}

/**
 * Request parameters for adminItemTypesControllerUpdateItemType operation in V1AdminItemTypesApi.
 * @export
 * @interface V1AdminItemTypesApiAdminItemTypesControllerUpdateItemTypeRequest
 */
export interface V1AdminItemTypesApiAdminItemTypesControllerUpdateItemTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof V1AdminItemTypesApiAdminItemTypesControllerUpdateItemType
     */
    readonly itemTypeId: string

    /**
     * 
     * @type {AdminUpdateItemTypeDto}
     * @memberof V1AdminItemTypesApiAdminItemTypesControllerUpdateItemType
     */
    readonly adminUpdateItemTypeDto: AdminUpdateItemTypeDto
}

/**
 * V1AdminItemTypesApi - object-oriented interface
 * @export
 * @class V1AdminItemTypesApi
 * @extends {BaseAPI}
 */
export class V1AdminItemTypesApi extends BaseAPI {
    /**
     * 
     * @summary Get item type by ID
     * @param {V1AdminItemTypesApiAdminItemTypesControllerGetItemTypeByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminItemTypesApi
     */
    public adminItemTypesControllerGetItemTypeById(requestParameters: V1AdminItemTypesApiAdminItemTypesControllerGetItemTypeByIdRequest, options?: AxiosRequestConfig) {
        return V1AdminItemTypesApiFp(this.configuration).adminItemTypesControllerGetItemTypeById(requestParameters.itemTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paginated item types with localizations list
     * @param {V1AdminItemTypesApiAdminItemTypesControllerGetItemTypesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminItemTypesApi
     */
    public adminItemTypesControllerGetItemTypesList(requestParameters: V1AdminItemTypesApiAdminItemTypesControllerGetItemTypesListRequest = {}, options?: AxiosRequestConfig) {
        return V1AdminItemTypesApiFp(this.configuration).adminItemTypesControllerGetItemTypesList(requestParameters.limit, requestParameters.page, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update localizations and machine names of item type
     * @param {V1AdminItemTypesApiAdminItemTypesControllerUpdateItemTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminItemTypesApi
     */
    public adminItemTypesControllerUpdateItemType(requestParameters: V1AdminItemTypesApiAdminItemTypesControllerUpdateItemTypeRequest, options?: AxiosRequestConfig) {
        return V1AdminItemTypesApiFp(this.configuration).adminItemTypesControllerUpdateItemType(requestParameters.itemTypeId, requestParameters.adminUpdateItemTypeDto, options).then((request) => request(this.axios, this.basePath));
    }
}
