import { Card, CardContent, CardHeader } from '@mui/material';
import { FC } from 'react';
import Toggle from 'react-styled-toggle';

import { Modal } from '@features';

import { usePaymentsSettings } from '../model';
import styles from './styles.module.css';

export const SettingsList: FC = () => {
  const { isPaymentsEnabled, togglePaymentsSettings } = usePaymentsSettings();
  const { isVisible, open, close } = Modal.useModal();

  const confirmButton = () => {
    togglePaymentsSettings();
    close();
  };

  return (
    <>
      <CardHeader title="Настройки" />

      <Card>
        <CardContent className={styles.root}>
          <Toggle checked={isPaymentsEnabled} onChange={open} labelRight="Платный функционал" />

          <Modal.UI
            isVisible={isVisible}
            title="Подтвердите действие"
            text={`Вы дейстительно ходите ${
              isPaymentsEnabled ? 'выключить' : 'включить'
            } платный функционал?`}
            close={close}
            confirmButton={confirmButton}
          />
        </CardContent>
      </Card>
    </>
  );
};
