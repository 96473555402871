import React, { FC } from 'react';

import * as Processes from '@processes';

import * as Providers from './providers';

export const App: FC = () => {
  return (
    <Providers.Axios>
      <Providers.Admin>{Processes.Resources}</Providers.Admin>
    </Providers.Axios>
  );
};
