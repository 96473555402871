import React, { FC } from 'react';
import { ArrayInput, BooleanInput, SimpleFormIterator, TextInput } from 'react-admin';

type PhotoUrlsInputProps = {
  label: string;
  name: string;
  source: string;
};
export const PhotoUrlsInput: FC<PhotoUrlsInputProps> = ({ label, source, name }) => {
  return (
    <ArrayInput source={source} name={name} label={label}>
      <SimpleFormIterator inline={true} disableReordering={true} disableClear={true} fullWidth={true}>
        <TextInput source="photoUrl" label="Ссылка" fullWidth={true} style={{ width: '70%' }} />
        <BooleanInput source="isCover" label="Основное" defaultValue={false} />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
