import axios from 'axios';
import axiosRetry from 'axios-retry';

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
  validateStatus: (status) => status <= 500,
  timeout: 10000,
});

axiosRetry(httpClient, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 5000;
  },
});
